import { createSlice } from "@reduxjs/toolkit";
import { getUserTests } from "./userTestsApi";

const initialState = {
    data: {},
    loading: 'pending',
    error: null
};

const getUserTestsSlice = createSlice({
    name: 'userTests',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserTests.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getUserTests.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(getUserTests.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectUserTests = (state) => state.userTests.data

export const userTestsReducer = getUserTestsSlice.reducer









