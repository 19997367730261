import { createSlice } from "@reduxjs/toolkit";
import { getCategory } from "./categoryApi";

const initialState = {
    data: {}
};

const getCategorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategory.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectCategory = (state) =>state.category.data

export const categoryReducer = getCategorySlice.reducer









