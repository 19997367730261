import { createSlice } from "@reduxjs/toolkit";
import { postRegistr } from "./RegisterApi";

const initialState = {
    data: [],
    loading: 'pending',
    error: null
};

const postRegistrSlice = createSlice({
    name: 'registr',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(postRegistr.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(postRegistr.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(postRegistr.rejected, (state, action) => {
                state.loading = 'rejected'
                state.error = action.payload
                console.log(action.payload, '000');
            })
    }
})

export const selectRegistr = (state) => state.registr
export const selectRegistrData = (state) => state.registr.data
export const selectRegistrLoading = (state) => state.registr.loading
export const selectRegistrError = (state) => state.registr.error

export const registrReducer = postRegistrSlice.reducer









