import { createAsyncThunk } from "@reduxjs/toolkit";
import instance  from "../../../axios/axios";

export const postAnswers = createAsyncThunk(
    'answers/postAnswers',
    async (body, thunkAPI) => {
        try {
            const config = {
                method: 'POST',
                url: 'pass-test',
                data: body,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

