import { createSlice } from "@reduxjs/toolkit";
import { getAllTestsBox, getTest, getTests } from "./getTestApi";

const initialState = {
    data: {},
    loading: 'pending',
    test: {},
    allTests: {},
};

const getAllTestsSlice = createSlice({
    name: 'getAllTests',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getTests.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getTests.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(getTests.rejected, (state, action) => {
                state.loading = 'rejected'
            })
            // -------------------------one_test----------------------
            .addCase(getTest.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getTest.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.test = action.payload
            })
            .addCase(getTest.rejected, (state, action) => {
                state.loading = 'rejected'
            })
            // --------------------------all_tests--------------------
            .addCase(getAllTestsBox.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getAllTestsBox.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.allTests = action.payload
            })
            .addCase(getAllTestsBox.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectAllTestsData = (state) => state.getAllTests.data
export const selectTestData = (state) => state.getAllTests.test
export const selectAllTestsBox = (state) => state.getAllTests.allTests
export const selectAllTestsLoading = (state) => state.getAllTests.loading

export const getAllTestsReducer = getAllTestsSlice.reducer









