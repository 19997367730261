import React, { memo, useEffect, useRef, useState } from 'react';
import './Change_Question.css';
import Decoration from '../Decoration/Decoration';
import { stage_background, window_background } from '../../images/images';
import { left, load_file } from '../../icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectGetChangeData } from '../../store/storeSlices/changeSlice/changeSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { getChange, putChange } from '../../store/storeSlices/changeSlice/changeApi';
import { convertFileBase64 } from '../../helpers/convertFunction';
import Loader from '../Loader/Loader';

function Change_Question() {
    const questions = useSelector(selectGetChangeData);
    const [question, setQuestion] = useState({});
    const [allQuestions, setAllQuestions] = useState({});
    const { firstId, secondId } = useParams();
    const dispatch = useDispatch();
    const lang = localStorage.getItem('lang') || 'en';
    const navigate = useNavigate();
    const questionRef = useRef();
    const answerRefs = useRef([]);
    const markRefs = useRef([]);
    const answerTypeRefs = useRef([]);
    const [fileName, setFileName] = useState('Add Image');
    const [load, setLoad] = useState(false);

    useEffect(() => {
        dispatch(getChange(firstId)).then(res => {
            if (res.payload && res.payload.data && res.payload.data.questions) {
                const quest = res.payload.data.questions.find((el, indx) => (indx + 1) == secondId);
                setQuestion(quest || {});
            }
        })
    }, [firstId, secondId, dispatch]);

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setFileName(event.target.files[0].name);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFileName('Add Image');

        const image = e.target[1].files[0];
        let base64Image = null;

        if (image) {
            try {
                base64Image = await convertFileBase64(image);
            } catch (error) {
                console.error('Error converting file to base64:', error);
            }
        }

        const questionText = questionRef.current?.value || '';

        const answer_options = question?.answer_options?.reduce((acc, el, index) => {
            const answer = {
                text: answerRefs.current[index]?.value || '',
                mark: markRefs.current[index]?.value || '',
                true_answer: answerTypeRefs.current[index]?.checked ? 1 : 0
            };

            acc[el?.id] = answer;
            return acc;
        }, {}) || {};

        const formData = {
            [question?.id]: {
                text: questionText,
                path: base64Image,
                answer_options
            }
        };

        await setAllQuestions(prevQuestions => ({
            ...prevQuestions,
            ...formData
        }));

        if (Number(secondId) !== (questions?.data?.question_count || 0)) {
            navigate(`/${lang}/change-test/${firstId}/${Number(secondId) + 1}`);
        }

    }

    useEffect(() => {
        if (Number(secondId) === (questions?.data?.question_count)) {

            const obj = {
                category_id: questions?.data?.category_id,
                name: questions?.data?.name,
                description: questions?.data?.description,
                time_in_seconds: questions?.data?.time_in_seconds,
                answer_type_id: questions?.data?.answer_type_id,
                test_grade_types: questions?.data?.test_grade_types,
                questions: allQuestions
            }
            console.log(obj, 'ertetgfh');
            console.log(obj.questions, '7777');

            setLoad(true)
            dispatch(putChange({ data: obj, id: firstId })).then(res=> console.log(res)).finally(()=> {
                setLoad(false)
            })

            navigate(`/${lang}/change-test/${firstId}/1`);
        }
    }, [allQuestions])

    const handleLeftClick = () => {
        if (Number(secondId) > 1) {
            navigate(`/${lang}/change-test/${firstId}/${Number(secondId) - 1}`);
        }
    }

    console.log(question, 'Updated Questions');


    return (
        <div className='change_question' style={{ backgroundImage: `url(${stage_background})` }}>
            <div className="container">
                <div className='change_question_content'>
                    <Decoration />
                    <div>
                        <div className='change_tests_box'>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel excepturi possimus
                                dolores fugit temporibus corporis impedit eius nulla deleniti numquam minima dolorum
                                ea nisi dignissimos pariatur, aut laborum velit amet, quisquam veritatis sint eveniet. Natus.
                            </p>
                            <form onSubmit={handleSubmit}>
                                <div className='change_question'>
                                    <span>Write down question here</span>
                                    <textarea ref={questionRef} defaultValue={question?.text || ''}></textarea>
                                </div>
                                <div className='img_change_box'>
                                    <div className="change_image_container file-input">
                                        <input
                                            type="file"
                                            name="file"
                                            id="file-input"
                                            className="file-input__input"
                                            onChange={handleFileChange}
                                            autoComplete='off'
                                        />
                                        <label className="file-input__label" htmlFor="file-input">
                                            {fileName === 'Add Image' && load_file}
                                            <span>{fileName}</span>
                                        </label>
                                    </div>
                                    {question?.path && <img src={question?.path} alt="not found"/>}
                                </div>
                                <div className='change_answer_box'>
                                    <span>You can write here all of variants of answers</span>
                                    {
                                        question?.answer_options?.map((el, index) => (
                                            <div key={el?.id} className='change_answer'>
                                                <input
                                                    type="text"
                                                    defaultValue={el?.text || ''}
                                                    autoComplete='off'
                                                    ref={el => answerRefs.current[index] = el}
                                                />
                                                <div className='change_test_check'>
                                                    <input
                                                        type={questions?.data?.answer_type_id === 2 ? 'checkbox' : 'radio'}
                                                        id={`rdo${index}`}
                                                        className="radio-input"
                                                        name='change_test'
                                                        defaultChecked={el?.true_answer === 1 ? true : false}
                                                        ref={el => answerTypeRefs.current[index] = el}
                                                    />
                                                    <label htmlFor={`rdo${index}`} className="radio-label">
                                                        <span className="radio-border"></span>
                                                    </label>
                                                </div>
                                                <input
                                                    type="number"
                                                    min='0'
                                                    autoComplete='off'
                                                    defaultValue={el?.true_answer !== 1 ? 0 : el.mark}
                                                    ref={el => markRefs.current[index] = el}
                                                />
                                                {console.log(el, '999999')}
                                            </div>
                                        )) || []
                                    }
                                </div>
                                <button style={{
                                    backgroundColor: Number(secondId) === (questions?.data?.question_count || 0) ? 'var(--light-blue)'
                                        : 'white',
                                    color: Number(secondId) === (questions?.data?.question_count || 0) ? 'white'
                                        : 'var(--light-blue)'
                                }}>
                                    {Number(secondId) === (questions?.data?.question_count || 0) ? 'Save' : 'Next'}
                                </button>
                            </form>
                        </div>
                        <div className="change_arrow">
                            <span onClick={handleLeftClick}>{left}</span>
                        </div>
                    </div>
                </div>
            </div>
            {load && <Loader/>}
        </div>
    );
}

export default memo(Change_Question);
