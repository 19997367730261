// createAllSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    stg1Inf: {},
    answer_type_id: null,
    grade_type: [],
    question: [],
};

const createAllSlice = createSlice({
    name: 'createAll',
    initialState,
    reducers: {
        setStg1Inf(state, action) {
            state.stg1Inf = action.payload;
        },
        setType(state, action) {
            state.answer_type_id = action.payload;
        },
        setGradeType(state, action) {
            state.grade_type = action.payload;
        },
        setQuestion(state, action) {
            const existingIndex = state.question.findIndex(el => el.test_id === action.payload.test_id);
            if (existingIndex !== -1) {
                state.question[existingIndex] = action.payload;
            } else {
                state.question.push(action.payload);
            }
        },
        updateTestText(state, action) {
            const { testId, newText } = action.payload;
            const testIndex = state.question.findIndex(t => t.test_id === testId);
            if (testIndex !== -1) {
                state.question[testIndex] = {
                    ...state.question[testIndex],
                    text: newText,
                };
            }
        },
        updateAnswerText(state, action) {
            const { testId, answerIndex, newText } = action.payload;
            const testIndex = state.question.findIndex(t => t.test_id === testId);
            if (testIndex !== -1) {
                const updatedAnswers = [...state.question[testIndex].answer_option];
                updatedAnswers[answerIndex] = {
                    ...updatedAnswers[answerIndex],
                    text: newText,
                };
                state.question[testIndex] = {
                    ...state.question[testIndex],
                    answer_option: updatedAnswers,
                };
            }
        },
    },
});

export const selectCreateAll = (state) => state.createAll;
export const selectStg1Inf = (state) => state.createAll.stg1Inf;
export const selectType = (state) => state.createAll.answer_type_id;
export const selectGradeType = (state) => state.createAll.grade_type;
export const selectQuestion = (state) => state.createAll.question;

export const {
    setStg1Inf,
    setType,
    setGradeType,
    setQuestion,
    updateTestText,
    updateAnswerText,
} = createAllSlice.actions;

export const createAllReducer = createAllSlice.reducer;
