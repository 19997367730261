import React, { memo } from 'react';
import './Nav_Menu_Item.css';
import { NavLink } from 'react-router-dom';

function Nav_Menu_Item({ txt, url }) {
    const lang = localStorage.getItem('lang') || 'en';

    return (
        <div className='nav_menu_item'>
            <NavLink to={`/${lang}/${url}`}>{txt}</NavLink>
        </div>
    )
}

export default memo(Nav_Menu_Item)