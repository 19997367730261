import { createAsyncThunk } from "@reduxjs/toolkit";
import  instance  from "../../../axios/axios";

export const postRegistr = createAsyncThunk(
    'registr/postRegistr',
    async (registration, thunkAPI) => {
        try {
            const config = {
                method: 'POST',
                url: 'auth/register',
                data: registration,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.errors.password[0])
        }
    }
)

