import React, { memo, useEffect, useState } from 'react';
import './Pass_Test.css';
import { left } from '../../icons/icons';
import { selectTestData } from '../../store/storeSlices/getTestSlice/getTestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTest } from '../../store/storeSlices/getTestSlice/getTestApi';
import { postAnswers } from '../../store/storeSlices/postAnswersSlice/postAnswersApi';

function Pass_Test() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { firstId, secondId } = useParams();
    const location = useLocation();
    const test = useSelector(selectTestData);
    const [question, setQuestion] = useState({});
    const lang = localStorage.getItem('lang') || 'en';
    const [answers, setAnswers] = useState([]);
    const [unfinished, setUnfinished] = useState({});
    const testCount = useSelector(selectTestData);
    const [count, setCount] = useState(testCount?.data?.time_in_seconds || 0);

    useEffect(() => {
        if (testCount?.data?.time_in_seconds) {
            setCount(testCount.data.time_in_seconds);
        }
    }, [testCount, secondId]);

    useEffect(() => {
        if (count > 0 && !isNaN(count)) {
            const timer = setTimeout(() => {
                setCount(count - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
        else {
            navigate(`/${lang}/test/${firstId}`)
        }
    }, [count]);

    useEffect(() => {
        if (firstId) {
            dispatch(getTest(firstId));
        }
    }, [dispatch, firstId]);

    useEffect(() => {
        if (test?.data?.questions) {
            setQuestion(
                test.data.questions.find((el, index) => (index + 1) === Number(secondId))
            );
        }
    }, [test, secondId]);

    useEffect(() => {
        const savedAnswers = localStorage.getItem('userAnswers');
        if (savedAnswers) {
            const parsedAnswers = JSON.parse(savedAnswers);
            setUnfinished(parsedAnswers);
            setAnswers(parsedAnswers.answers || []);
        }
    }, []);

    const handleAnswerChange = (e, id) => {
        const isChecked = e.target.checked;

        let updatedAnswers;
        if (test?.data?.answer_type_id !== 2) {

            updatedAnswers = answers.filter(answer => answer.question_Id !== secondId);
            if (isChecked) {
                updatedAnswers.push({ question_Id: secondId, answer_Id: id });
            }
        } else {
            const answerIndex = answers.findIndex(answer => answer.question_Id === secondId);
            if (answerIndex !== -1) {
                const updatedAnswer = answers[answerIndex];
                updatedAnswer.answer_Id = isChecked
                    ? [...updatedAnswer.answer_Id, id]
                    : updatedAnswer.answer_Id.filter(answerId => answerId !== id);
                updatedAnswers = [
                    ...answers.slice(0, answerIndex),
                    updatedAnswer,
                    ...answers.slice(answerIndex + 1)
                ];
            } else {
                updatedAnswers = [...answers, { question_Id: secondId, answer_Id: [id] }];
            }
        }

        setAnswers(updatedAnswers);
        localStorage.setItem("userAnswers", JSON.stringify({ test_link: firstId, answers: updatedAnswers }));

        if (isChecked && Number(secondId) !== Number(test?.data?.question_count) && test?.data?.answer_type_id === 1) {
            setTimeout(() => {
                navigate(`/${lang}/test/${firstId}/${Number(secondId) + 1}`);
            }, 200);
        }
        else if (Number(secondId) === Number(test?.data?.question_count) && test?.data?.answer_type_id === 1) {
            if (test?.data?.answer_type_id === 1) {
                dispatch(postAnswers({
                    test_link: firstId,
                    true_answer_option: updatedAnswers.map(a => a.answer_Id).flat()
                }));
            }

            if (firstId === test?.data.link) {
                localStorage.removeItem("userAnswers");
            }

            setTimeout(() => {
                navigate(`/${lang}/test-result`);
            }, 200);
        }
    };

    const handleLeftClick = () => {
        if (Number(secondId) > 1) {
            navigate(`/${lang}/test/${firstId}/${Number(secondId) - 1}`);
        }
        else if (Number(secondId) === 1) {
            navigate(`/${lang}/test/${firstId}`);
        }
    }

    const handleDblClick = () => {
        const answerIds = answers.map(answer => answer.answer_Id).flat();

        if (Number(secondId) !== Number(test?.data?.question_count)) {
            setTimeout(() => {
                navigate(`/${lang}/test/${firstId}/${Number(secondId) + 1}`);
            }, 200);
        }

        if (Number(secondId) === Number(test?.data?.question_count) && test?.data?.answer_type_id === 2) {
            dispatch(postAnswers({
                test_link: firstId,
                true_answer_option: answerIds
            }));
            if (firstId === test?.data.link) {
                localStorage.removeItem("userAnswers");
            }
            setTimeout(() => {
                navigate(`/${lang}/test-result`);
            }, 200);
        }
    }

    return (
        <div className="pass_test">
            <div className="container">
                <div className="pass_test_content">
                    <div className="pass_test_page">
                        <span>{secondId}</span>
                        <span>/</span>
                        <span>{test?.data?.question_count}</span>
                    </div>
                    <div className='pass_test_ans'>
                        <div className="pass_test_box">
                            {test?.data?.path && (
                                <div className="pass_test_img">
                                    <img src={test?.data?.path} alt="test illustration" />
                                </div>
                            )}
                            <p className="pass_test_question">{question?.text}</p>
                            <div className="pass_test_answers">
                                {question?.answer_options?.map((el, index) => (
                                    <div key={el?.id} className='pass_test_answer'>
                                        <div className="pass_test_check">
                                            <input
                                                type={test?.data?.answer_type_id === 2 ? "checkbox" : "radio"}
                                                id={`rdo${index}`}
                                                className="radio-input"
                                                name={test?.data?.answer_type_id === 2 ? `chk${index}` : 'rad'}
                                                checked={
                                                    test?.data?.answer_type_id === 2
                                                        ? answers.some(answer => answer.question_Id === secondId && answer.answer_Id.includes(el.id))
                                                        : answers.some(answer => answer.question_Id === secondId && answer.answer_Id === el.id)
                                                }
                                                onChange={(e) => handleAnswerChange(e, el.id)}
                                            />
                                            <label htmlFor={`rdo${index}`} className="radio-label">
                                                <span className="radio-border"></span>
                                            </label>
                                        </div>
                                        <span>{el?.text}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="pass_test_arrow_timer">
                            <span onClick={handleLeftClick}>{left}</span>
                            {test?.data?.answer_type_id === 2 ? (
                                <button onClick={handleDblClick}>Next</button>
                            ) : (
                                <p className='pomodoro_timer'>{count}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Pass_Test);
