import React, { useState, useEffect, memo } from 'react';
import './Test_Answer.css';
import { useSelector } from 'react-redux';
import { selectQuestion, selectType } from '../../store/storeSlices/createAllSlice/createAllSlice';
import { useParams } from 'react-router-dom';

function Test_Answer({ index, onChange, letter, handleRadioChange, setMarkError }) {
    const [textValue, setTextValue] = useState('');
    const [trueAnswer, setTrueAnswer] = useState(0);
    const [markValue, setMarkValue] = useState('');
    const checkType = useSelector(selectType);
    const letters = 'ABCDEFGH'.split('');
    const [answersValue, setAnswersValue] = useState([]);
    const { id } = useParams();
    const question = useSelector(selectQuestion);

    useEffect(() => {
        onChange(index, { text: textValue, true_answer: trueAnswer, mark: markValue || '0' });
    }, [textValue, trueAnswer, markValue]);

    const handleTextChange = (e) => setTextValue(e.target.value);

    const handleTrueAnswerChange = (e) => {
        const isChecked = e.target.checked;
        setTrueAnswer(isChecked ? 1 : 0);
        handleRadioChange(index); 
    };

    const handleMarkChange = (e) => {
        const value = e.target.value;
        setMarkValue(value);

        // Check if the mark value is valid when a correct answer is selected
        if (trueAnswer === 1 && (!value || Number(value) <= 0)) {
            setMarkError("Mark value must be greater than 0 if this is the correct answer.");
        } else {
            setMarkError('');
        }
    };

    useEffect(() => {
        if (question) {
            const currentQuestion = question.find(el => el.test_id === id);
            if (currentQuestion && currentQuestion.answer_option) {
                setAnswersValue(currentQuestion.answer_option);
            }
        }
    }, [id, question]);

    useEffect(() => {
        if (answersValue[index]) {
            setTextValue(answersValue[index].text || '');
            setTrueAnswer(answersValue[index].true_answer || 0);
            setMarkValue(answersValue[index].mark || '');
        }
    }, [answersValue, index]);

    return (
        <div className='test_answer'>
            <div className='test_answer_box_error_field'>
                <div className='test_field'>
                    <span>{letters[letter]}</span>
                    <input
                        type="text"
                        placeholder={`${index + 1} variant of answer*`}
                        autoComplete='off'
                        value={textValue}
                        onChange={handleTextChange}
                    />
                </div>
            </div>
            <div className='test_points_answer_box'>
                <div className='test_answer_check'>
                    <span>Right answer*</span>
                    <div className='test_check'>
                        <input
                            type={checkType === 2 ? 'checkbox' : 'radio'}
                            id={`rdo${index}`}
                            className="radio-input"
                            name={checkType === 2 ? `rad${index}` : 'rad'}
                            onChange={handleTrueAnswerChange}
                            checked={trueAnswer === 1}
                        />
                        <label htmlFor={`rdo${index}`} className="radio-label">
                            <span className="radio-border"></span>
                        </label>
                    </div>
                </div>
                <div className='test_points'>
                    <span>Mark*</span>
                    <input
                        type="number"
                        min='0'
                        autoComplete='off'
                        value={markValue}
                        onChange={handleMarkChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default memo(Test_Answer);
