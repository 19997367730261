import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../axios/axios";

export const deleteTest = createAsyncThunk(
    'test/deleteTest',
    async (id, thunkAPI) => {
        try {
            const config = {
                method: 'DELETE',
                url: `auth/delete/${id}`,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

