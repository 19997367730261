import React, { memo, useEffect, useState } from 'react';
import './Start_Test_Write.css';
import { category_1, category_2, category_3, category_4, stage_background } from '../../images/images';
import { useDispatch, useSelector } from 'react-redux';
import { selectTestData } from '../../store/storeSlices/getTestSlice/getTestSlice';
import { getTest } from '../../store/storeSlices/getTestSlice/getTestApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../Loading/Loading';

function Start_Test_Write() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const test = useSelector(selectTestData);
    const { id } = useParams();
    const location = useLocation();
    const lang = localStorage.getItem('lang') || 'en';
    const [unfinished, setUnfinished] = useState({});

    useEffect(() => {
        dispatch(getTest(id));
    }, [id]);

    useEffect(() => {
        setUnfinished(JSON.parse(localStorage.getItem("userAnswers")))
    }, [])

    const getCategoryImage = (categoryId) => {
        switch (categoryId) {
            case 1:
                return category_3;
            case 2:
                return category_2;
            case 3:
                return category_1;
            case 4:
                return category_4;
            default:
                return null;
        }
    };

    const handleTestClick = () => {
        navigate(`/${lang}/test/${test?.data?.link}/1`);
        localStorage.removeItem("userAnswers");
    }

    const handleUnfinishedClick = () => {
        navigate(`/${lang}/test/${unfinished?.test_link}/${Number(unfinished?.answers?.length) + 1}`);
    }

    return (
        <div className="start_test_write" style={{ backgroundImage: `url(${stage_background})` }}>
            <div className="container">
                <div className="start_test_write_content">
                    <div className="start_test_write_content_img">
                        {test?.data?.category_id ? (
                            <img src={getCategoryImage(test.data.category_id)} alt="not found" />
                        ) : (
                            <Loading />
                        )}
                    </div>
                    <h1>Lorem ipsum dolor sit amet, consectetur.</h1>
                    {
                        unfinished?.test_link === id && unfinished?.answers &&
                        <span>Lorem ipsum dolor sit amet.</span>
                    }
                    <div className='start_test_write_content_btn'>
                        {
                            unfinished?.test_link === id && unfinished?.answers &&
                            <button className='start_test_write_content_btn_1' onClick={handleUnfinishedClick}>Continue</button>
                        }
                        <button className='start_test_write_content_btn_2' onClick={handleTestClick}>Start</button>
                    </div>
                    <div className='start_test_description'>fsvdgdghtg</div>
                </div>
            </div>
        </div>
    )
}

export default memo(Start_Test_Write);
