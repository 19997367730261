import React, { memo } from 'react';
import './Contact_Us.css';
import { facebook, instagram, twitter } from '../../icons/icons';
import { contact_us, other_background, window_background } from '../../images/images';
import Contact_Us_Formik from '../Contact_Us_Formik/Contact_Us_Formik';

function Contact_Us() {
    return (
        <div className='contact_us' style={{backgroundImage: `url(${other_background})`}}>
            <div className="container">
                <div className="contact_us_content">
                    <div className="contact_us_box" style={{ backgroundImage: `url(${window_background})` }}>
                        <div className="contact_us_message">
                            <div className='contact_us_message_text'>
                                <h1>CONTACT US</h1>
                                <p>Contact Us directly or fill out the form and we will get back to you properly.</p>
                            </div>
                            <Contact_Us_Formik />
                        </div>
                        <div className="contact_us_image">
                            <div className='contact_us_img'>
                                <img src={contact_us} alt="not found" />
                            </div>
                            <nav>
                                <a href=""><span>{twitter}</span></a>
                                <a href=""> <span>{facebook}</span></a>
                                <a href=""><span>{instagram}</span></a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Contact_Us)