import React, { memo, useEffect, useRef, useState } from 'react';
import './Phsychological_Tests.css';
import Tests_Logo from '../Tests_Logo/Tests_Logo';
import { phsychological_decoration_left, phsychological_decoration_right } from '../../icons/icons';
import { phsychological_img_1, phsychological_img_2, phsychological_img_3 } from '../../images/images';

function Phsychological_Tests() {
    const phsychologicalRef = useRef(null);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;

            if (phsychologicalRef.current && offset > phsychologicalRef.current.offsetTop - 500) {
                setDisplay(true);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <div className="phsychological_tests_content" ref={phsychologicalRef}>
            <Tests_Logo logo='Phsychological Tests' />
            <div className='phsychological_tests_description'>
                <div>
                    {
                        display &&
                        <div className='phsychological_tests_img phy-1'>
                            <img src={phsychological_img_1} alt="not found" />
                        </div>
                    }
                    <span>Have messy thoughts?</span>
                </div>
                <span style={{ transform: 'translateY(40%)' }}>{phsychological_decoration_left}</span>
                <div style={{ transform: 'translateY(40%)' }}>
                    {
                        display &&
                        <div className='phsychological_tests_img phy-2'>
                            <img src={phsychological_img_2} alt="not found" />
                        </div>
                    }
                    <span>Do some Tests!</span>
                </div>
                <span style={{ transform: 'translateY(40%)' }}>{phsychological_decoration_right}</span>
                <div style={{ transform: 'translateY(-40%)' }}>
                    {
                        display &&
                        <div className='phsychological_tests_img phy-3'>
                            <img src={phsychological_img_3} alt="not found" />
                        </div>
                    }
                    <span>Become more Relaxed!</span>
                </div>
            </div>
        </div>
    )
}

export default memo(Phsychological_Tests)