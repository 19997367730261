import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Congratulations from './components/Congratulations/Congratulations';
import Contact_Us from './components/Contact_Us/Contact_Us';
import Sign_Up from './components/Sign_Up/Sign_Up';
import Home_Wrapper from './components/Page/Home_Wrapper';
import Errore_Page from './components/Error_Page/Errore_Page';
import Home from './components/Home/Home';
import Nickname_For_Test from './components/Nickname_For_Test/Nickname_For_Test';
import Create_Stage_One from './components/Create_Stage_One/Create_Stage_One';
import Create_Stage_Two from './components/Create_Stage_Two/Create_Stage_Two';
import Create_Stage_Three from './components/Create_Stage_Three/Create_Stage_Three';
import Create_Stage_Four from './components/Create_Stage_Four/Create_Stage_Four';
import Sign_In from './components/Sign_In/Sign_In';
import Check_One_Test from './components/Check_One_Test/Check_One_Test';
import PrivateRouteForOutSider from './privateRoute/PrivateRouteForOutsider';
import { useEffect } from 'react';
import Profile from './components/Profile/Profile';
import PrivateRouteForRegandLog from './privateRoute/PrivateRouteForRegandLog';
import PrivateRoute from './privateRoute/PrivateRoute';
import Start_Test_Write from './components/Start_Test_Write/Start_Test_Write';
import Test_Result from './components/Test_Result/Test_Result';
import Main from './components/Main/Main';
import Pass_Test from './components/Pass_Test/Pass_Test';
import Change_Question from './components/Change_Questions/Change_Question';

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';

  useEffect(() => {
    pathname === '/' && navigate(`/${lang}/`)
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home_Wrapper />}>
          <Route path=':lang'>

            {/* -----------------------------Home------------------------------- */}

            <Route index element=
              {
                <PrivateRouteForOutSider>
                  <Home />
                </PrivateRouteForOutSider>
              }
            />

            {/* ---------------------------------Main-------------------------- */}

            <Route path='test'>
              <Route index element=
                {
                  <PrivateRouteForOutSider>
                    <Main />
                  </PrivateRouteForOutSider>
                }
              />
              <Route path=':id' element=
                {
                  <PrivateRouteForOutSider>
                    <Start_Test_Write />
                  </PrivateRouteForOutSider>
                }
              />
              <Route path=":firstId/:secondId" element=
                {
                  <PrivateRouteForOutSider>
                    <Pass_Test />
                  </PrivateRouteForOutSider>
                }
              />
            </Route>

            {/* ----------------------------Contact_Us------------------------- */}

            <Route path='contact-us' element=
              {
                <PrivateRouteForOutSider>
                  <Contact_Us />
                </PrivateRouteForOutSider>
              }
            />
            <Route path='thanks-for-the-request' element=
              {
                <PrivateRouteForOutSider>
                  <Congratulations />
                </PrivateRouteForOutSider>
              }
            />

            {/* ---------------------------Sign_Up_In----------------------------- */}

            <Route path='auth'>
              <Route path='sign-up' element=
                {
                  <PrivateRouteForRegandLog>
                    <Sign_Up />
                  </PrivateRouteForRegandLog>
                }
              />
              <Route path='sign-in' element=
                {
                  <PrivateRouteForRegandLog>
                    <Sign_In />
                  </PrivateRouteForRegandLog>
                }
              />
            </Route>
            <Route path='signed-up' element=
              {
                <PrivateRouteForOutSider>
                  <Congratulations />
                </PrivateRouteForOutSider>
              }
            />

            {/* ----------------------Start_Making_Test------------------------ */}

            <Route path='start-making' element=
              {
                <PrivateRoute>
                  <Nickname_For_Test />
                </PrivateRoute>
              }
            />
            <Route path='stage-1' element=
              {
                <PrivateRoute>
                  <Create_Stage_One />
                </PrivateRoute>
              }
            />
            <Route path='stage-2' element=
              {
                <PrivateRoute>
                  <Create_Stage_Two />
                </PrivateRoute>
              }
            />
            <Route path='stage-3' element=
              {
                <PrivateRoute>
                  <Create_Stage_Three />
                </PrivateRoute>
              }
            />
            <Route path='stage-4/:id' element=
              {
                <PrivateRoute>
                  <Create_Stage_Four />
                </PrivateRoute>
              }
            />
            <Route path='completed-making' element=
              {
                <PrivateRouteForOutSider>
                  <Congratulations />
                </PrivateRouteForOutSider>
              }
            />

            {/* -------------------------Error_Page-------------------------- */}

            <Route path='*' element=
              {
                <PrivateRouteForOutSider>
                  <Errore_Page />
                </PrivateRouteForOutSider>
              }
            />

            {/* -------------------------------Check_Test-------------------- */}

            <Route path='check-test/:id' element=
              {
                <PrivateRoute>
                  <Check_One_Test />
                </PrivateRoute>
              }
            />

            {/* -------------------------Profile_Page------------------------ */}

            <Route path='profile' element=
              {
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            {/* -----------------------Start_Write_Test---------------------- */}

            <Route path='test-result' element=
              {
                <PrivateRouteForOutSider>
                  <Test_Result />
                </PrivateRouteForOutSider>
              }
            />

            {/* -----------------------Change_Question----------------------- */}

            <Route path='change-test/:firstId/:secondId' element=
              {
                <PrivateRoute>
                  <Change_Question />
                </PrivateRoute>
              }
            />

            {/* ------------------------------------------------------------- */}
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;



