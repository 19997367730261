import React, { memo, useEffect, useRef, useState } from 'react';
import './Entertaining_Tests.css';
import Tests_Logo from '../Tests_Logo/Tests_Logo';
import { entertaining_decoration_left, entertaining_decoration_right } from '../../icons/icons';
import { category_border, entertaining_img_1, entertaining_img_2, entertaining_img_3 } from '../../images/images';

function Entertaining_Tests() {
    const entertainingRef = useRef(null);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;

            if (entertainingRef.current && offset > entertainingRef.current.offsetTop - 500) {
                setDisplay(true);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <div className="entertaining_tests_content" ref={entertainingRef}>
            <Tests_Logo logo='Entertaining Tests' />
            <div className='entertaining_tests_description'>
                <div>
                    {
                        display &&
                        <div className='entertaining_tests_img ent-1'>
                            <img src={entertaining_img_1} alt="not found" />
                        </div>
                    }
                    <span>Being sad?</span>
                </div>
                <span style={{ transform: 'translateY(60%)' }}>{entertaining_decoration_left}</span>
                <div style={{ transform: 'translateY(60%)' }}>
                    {
                        display &&
                        <div className='entertaining_tests_img ent-2'>
                            <img src={entertaining_img_2} alt="not found" />
                        </div>
                    }
                    <span>Do some fun Tests!</span>
                </div>
                <span style={{ transform: 'translateY(60%)' }}>{entertaining_decoration_right}</span>
                <div>
                    {
                        display &&
                        <div className='entertaining_tests_img ent-3'>
                            <img src={entertaining_img_3} alt="not found" />
                        </div>
                    }
                    <span>Be more happy than ever!</span>
                </div>
            </div>
            <div className='category_border' style={{ backgroundImage: `url(${category_border})` }}></div>
        </div>
    )
}

export default memo(Entertaining_Tests)