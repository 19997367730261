import React, { memo } from 'react';
import './Test_Result.css';
import { category_3, stage_background, window_background } from '../../images/images';
import { useSelector } from 'react-redux';
import { selectPostAnswers } from '../../store/storeSlices/postAnswersSlice/postAnswersSlice';
import { selectTestData } from '../../store/storeSlices/getTestSlice/getTestSlice';
import { useNavigate } from 'react-router-dom';

function Test_Result() {
  const navigate=useNavigate();
  const characteristics = useSelector(selectPostAnswers);
  const test = useSelector(selectTestData);
  const lang = localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : 'en';

  const handleAgainClick = () => {
    navigate(`/${lang}/test/${test?.data?.link}/1`);
  }

  const handleMenuClick = () => {
    navigate(`/${lang}/test`);
  }

  return (
    <div className="test_result" style={{ backgroundImage: `url(${stage_background})` }}>
      <div className="container">
        <div className="test_result_content">
          <div className="test_result_content_img">
            <img src={category_3} alt="not found" />
          </div>
          <h2>Lorem ipsum dolor sit amet</h2>
          <div className="test_result_content_btn">
            <button className='test_result_content_1' onClick={handleAgainClick}>Go Again</button>
            <button className='test_result_content_2' onClick={handleMenuClick}>Other</button>
          </div>
          <h1>Your Results</h1>
          <div className='result_characteristics' style={{ backgroundImage: `url(${window_background})` }}>{characteristics?.data}</div>
        </div>
      </div>
    </div>
  )
}

export default memo(Test_Result)