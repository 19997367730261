import React, { memo } from 'react';
import './Sign_Up.css';
import Sign_Up_Formik from '../Sign_Up_Formik/Sign_Up_Formik';
import { other_background, sign_up, window_background } from '../../images/images';
import { useNavigate } from 'react-router-dom';

function Sign_Up() {
    const navigate = useNavigate();
    const lang = localStorage.getItem('lang') || 'en';

    return (
        <div className="sign_up" style={{ backgroundImage: `url(${other_background})` }}>
            <div className="container">
                <div className="sign_up_content">
                    <div className="sign_up_box" style={{ backgroundImage: `url(${window_background})` }}>
                        <div className="sign_up_info">
                            <h1>SIGN UP</h1>
                            <p>This will help you to create tests fast and without any problem!</p>
                            <Sign_Up_Formik />
                            <span className='sign_up_account'>Already have an account? Click to
                                <span onClick={() => navigate(`/${lang}/auth/sign-in`)}> Sign In!</span>
                            </span>
                        </div>
                        <div className="sign_up_img">
                            <img src={sign_up} alt="not found" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Sign_Up)