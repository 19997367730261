import React, { memo, useEffect, useRef, useState } from 'react';
import './Create_Stage_Four.css';
import { error, load_file } from '../../icons/icons';
import { stage_background, window_background } from '../../images/images';
import Test_Answer from '../Test_Answer/Test_Answer';
import Arrow from '../Arrow/Arrow';
import { convertFileBase64 } from '../../helpers/convertFunction';
import Decoration from '../Decoration/Decoration';
import PaginatedItems from '../PaginatedItems/PaginatedItems';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuestion, selectStg1Inf, setQuestion } from '../../store/storeSlices/createAllSlice/createAllSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Create_Stage_Four() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const lang = localStorage.getItem('lang') || 'en';
    const stg1Inf = useSelector(selectStg1Inf);
    const question = useSelector(selectQuestion);
    const textareaRef = useRef(null);
    const [inputs, setInputs] = useState([]);
    const [fileName, setFileName] = useState('Add Image');
    const [answers, setAnswers] = useState([]);
    const [errors, setErrors] = useState({ answerError: '', markError: '', questionError: '', radioError: '' });
    const [textareaValue, setTextareaValue] = useState('');
    const { t, i18n } = useTranslation();

    const addInput = () => {
        if (inputs.length < 8) {
            setInputs(prevInputs => [...prevInputs, { id: prevInputs.length }]);
        }
    };

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setFileName(event.target.files[0].name);
        }
    };

    const handleAnswerChange = (index, answerData) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = answerData;
        setAnswers(updatedAnswers);
    };

    const handleRadioChange = (index) => {
        const updatedAnswers = answers.map((answer, i) => ({
            ...answer,
            true_answer: i === index ? 1 : 0,
        }));
        setAnswers(updatedAnswers);
    };

    const validateForm = () => {
        const allAnswersValid = answers.every(answer => answer.text.trim() !== "");
        const questionTextValid = textareaRef.current.value.trim() !== "";
        const hasCorrectAnswer = answers.some(answer => answer.true_answer === 1);
        const markValid = answers.every(answer =>
            answer?.true_answer === 1 ? answer?.mark > 0 : true
        );
        let errors = { answerError: '', markError: '', questionError: '', radioError: '' };

        if (!questionTextValid) errors.questionError = 'Please fill in the question.';
        if (!allAnswersValid || answers.length < 3) errors.answerError = 'Please fill in all answers (minimum 3).';
        if (!hasCorrectAnswer) errors.radioError = 'Please select at least one correct answer.';
        if (!markValid) errors.markError = 'Mark value must be greater than 0 for the correct answer.';

        setErrors(errors);
        return questionTextValid && allAnswersValid && answers.length >= 3 && hasCorrectAnswer && markValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        const image = e.target[1].files[0];
        let base64Image = null;

        if (image) {
            try {
                base64Image = await convertFileBase64(image);
            } catch (error) {
                console.error('Error converting file to base64:', error);
                setErrors(prev => ({ ...prev, answerError: 'Error processing the image. Please try again.' }));
                return;
            }
        }

        const test_question = {
            test_id: id,
            path: base64Image,
            text: textareaRef.current.value,
            answer_option: answers
        };

        try {
            await dispatch(setQuestion(test_question));

            setInputs([]);
            setAnswers([]);
            setFileName('Add Image');
            setTextareaValue('');

            if (Number(id) < Number(stg1Inf?.question_count)) {
                navigate(`/${lang}/stage-4/${Number(id) + 1}`);
            } else if (Number(id) === Number(stg1Inf?.question_count)) {
                navigate(`/${lang}/check-test/1`);
            }
        }
        catch (error) {
            console.error('Error dispatching setQuestion:', error);
            setErrors(prev => ({ ...prev, answerError: 'Error saving the question. Please try again.' }));
        }
    };

    useEffect(() => {
        if (question) {
            const currentQuestion = question.find(el => el.test_id === id);
            if (currentQuestion) {
                setTextareaValue(currentQuestion.text);
            }
        }
    }, [id, question]);

    return (
        <div className='create_stage_four' style={{ backgroundImage: `url(${stage_background})` }}>
            <div className="container">
                <div className='create_stage_four_content'>
                    <Decoration />
                    <div className="create_stage_four_box">
                        <div className='tests_box'>
                            <p>
                                {t('stg4')}
                            </p>
                            <PaginatedItems {...{ setInputs, setAnswers, setFileName, setErrors, setTextareaValue }} />
                            <form className='create_stage_four_information' onSubmit={handleSubmit}>
                                <div className='create_stage_four_question_box'>
                                    <div className='create_stage_four_question'>
                                        <span>Write down question here</span>
                                        <textarea
                                            value={textareaValue}
                                            onChange={(e) => setTextareaValue(e.target.value)}
                                            ref={textareaRef}
                                        ></textarea>
                                        {
                                            errors.questionError &&
                                            <p className="create_stage_four_error_question">{errors.questionError}</p>
                                        }
                                    </div>
                                    <div className='create_stage_four_add_file'>
                                        <span>If you want to add an image click here</span>
                                        <div className="stage_input_container file-input">
                                            <input
                                                type="file"
                                                name="file"
                                                id="file-input"
                                                className="file-input__input"
                                                onChange={handleFileChange}
                                                autoComplete='off'
                                            />
                                            <label className="file-input__label" htmlFor="file-input">
                                                {fileName === 'Add Image' && load_file}
                                                <span>{fileName}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='test_answer_box'>
                                    <span>You can write here all of variants of answers</span>
                                    {inputs.map(item => (
                                        <Test_Answer
                                            key={item.id}
                                            index={item.id}
                                            letter={item.id}
                                            onChange={handleAnswerChange}
                                            handleRadioChange={() => handleRadioChange(item.id)}
                                            answerError={errors.answerError}
                                            markError={errors.markError}
                                            setMarkError={setMarkError => setErrors(prev => ({ ...prev, markError: setMarkError }))}
                                        />
                                    ))}
                                    <div className='test_answer_errors'>
                                        {errors.answerError && <p className='test_field_answer_error'>{errors.answerError}</p>}
                                        {errors.radioError && <p className='mark_error'>{error} {errors.radioError}</p>}
                                        {errors.markError && <p className='mark_error'>{error} {errors.markError}</p>}
                                    </div>
                                    <button
                                        type='button'
                                        onClick={addInput}
                                        aria-label="Add answer input"
                                    >
                                        +
                                    </button>
                                </div>
                                <button
                                    type='submit'
                                    style={{
                                        backgroundColor: id === stg1Inf?.question_count ? 'var(--light-blue)' : 'white',
                                        color: id === stg1Inf?.question_count ? 'white' : 'var(--light-blue)'
                                    }}
                                    aria-label="Save question"
                                >
                                    Save
                                </button>
                            </form>
                        </div>
                        <Arrow />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Create_Stage_Four);
