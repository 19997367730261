import { Formik } from 'formik';
import React, { memo } from 'react';
import * as yup from 'yup';
import './Contact_Us_Formik.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postContactUs } from '../../store/storeSlices/contactUsSlice/contactUsApi';

function Contact_Us_Formik() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const lang = localStorage.getItem('lang') || 'en';
    const validationSchema = yup.object().shape({
        name: yup.string().required('Пожалуйста, заполните это поле'),
        email: yup.string().email('Пожалуйста, укажите адрес эл. почты*').required('Пожалуйста, заполните это поле'),
        message: yup.string().required('Пожалуйста, заполните это поле')
    })

    const handleRegister = (e, handleSubmit, isValid) => {
        e.preventDefault();
        handleSubmit()

        const contact_inform = {
            name: e.target[0].value,
            email: e.target[1].value,
            message: e.target[2].value
        }

        if (isValid && e.target[0].value && e.target[1].value && e.target[2].value) {
            dispatch(postContactUs(contact_inform))
            navigate(`/${lang}/thanks-for-the-request`)
        }
    }

    return (
        <div>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    message: '',
                }}

                onSubmit={(values, { resetForm }) => {

                    resetForm()
                }}

                validateOnBlur

                validationSchema={validationSchema}>

                {
                    ({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                        <form className="contact_us_message_information" onSubmit={(e) => handleRegister(e, handleSubmit, isValid)}>
                            <div className="contact_us_name_inp">
                                <input type="text" name="name" placeholder='Your Name' value={values.name} onChange={handleChange} onBlur={handleBlur} autoComplete='off' />
                                {touched.name && errors.name && <p className="error">{errors.name}</p>}
                            </div>
                            <div className="contact_us_email_inp">
                                <input type="text" name="email" placeholder='Your Mail' value={values.email} onChange={handleChange} onBlur={handleBlur} autoComplete='off' />
                                {touched.email && errors.email && <p className="error">{errors.email}</p>}
                            </div>
                            <div className="contact_us_message_inp">
                                <textarea name="message" placeholder='Message' value={values.message} onChange={handleChange} onBlur={handleBlur}></textarea>
                                {touched.message && errors.message && <p className="error">{errors.message}</p>}
                            </div>
                            <button type='submit' className='contact_us_btn'>Send</button>
                        </form>
                    )
                }
            </Formik>
        </div>
    )
}

export default memo(Contact_Us_Formik)