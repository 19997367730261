import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../axios/axios";

// --------------------Answer_Type---------------------
export const getAnswerType = createAsyncThunk(
    'answerType/getAnswerType',
    async () => {
        try {
            const config = {
                method: 'GET',
                url: 'get-answer-types',
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch {
            console.log(Error, 'Error');
        }
    }
)
// --------------------------Grades-----------------------

export const getGrades = createAsyncThunk(
    'grades/getGrades',
    async () => {
        try {
            const config = {
                method: 'GET',
                url: 'get-grades',
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch {
            console.log(Error, 'Error');
        }
    }
)





