import { createSlice } from "@reduxjs/toolkit";
import { getAnswerType, getGrades } from "./getStageApi";

const initialState = {
    answer_type: {},
    grades: {}
};

const getTypeGrade = createSlice({
    name: 'answerTypeGrades',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAnswerType.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getAnswerType.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.answer_type = action.payload
            })
            .addCase(getAnswerType.rejected, (state, action) => {
                state.loading = 'rejected'
            })
            // ----------------------grades------------------------
            .addCase(getGrades.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getGrades.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.grades = action.payload
            })
            .addCase(getGrades.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectAnswerType = (state) => state.answerTypeGrades.answer_type
export const selectGrades = (state) => state.answerTypeGrades.grades

export const answerTypeGradesReducer = getTypeGrade.reducer









