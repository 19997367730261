import { Formik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import * as yup from 'yup';
import './Stage_One_Formik.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectStg1Inf, setStg1Inf } from '../../store/storeSlices/createAllSlice/createAllSlice';
import { selectCategory } from '../../store/storeSlices/categorySlice/categorySlice';
import { getCategory } from '../../store/storeSlices/categorySlice/categoryApi';
import { window_background } from '../../images/images';
import { useTranslation } from 'react-i18next';

function Stage_One_Formik() {
    const dispatch = useDispatch();
    const [category, setCategory] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const getCategories = useSelector(selectCategory);
    const stg1Inform = useSelector(selectStg1Inf);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        dispatch(getCategory());
    }, [dispatch]);

    const validationSchema = yup.object().shape({
        name: yup.string().required('Пожалуйста, заполните это поле'),
        sec: yup.number().max(60, 'Максимум 60!').required('Заполнить'),
        count: yup.number().max(20, 'Максимум 20!').required('Заполнить'),
        comment: yup.string().required('Пожалуйста, заполните это поле'),
        category: yup.string().required('Пожалуйста, заполните это поле'),
    });

    const handleItemClick = (item, setFieldValue) => {
        setCategory(item.text);
        setCategoryId(item.id);
        setFieldValue('category', item.text);
    };

    const handleRegister = (e, handleSubmit, isValid) => {
        e.preventDefault();
        handleSubmit();

        const test_inform = {
            category_id: categoryId,
            name: e.target[1].value,
            time_in_seconds: e.target[2].value,
            question_count: e.target[3].value,
            description: e.target[4].value
        };

        dispatch(setStg1Inf(test_inform));

        setCategory('');
    };

    useEffect(() => {
        console.log(stg1Inform, 'stg1Inform');
    }, [stg1Inform]);

    return (
        <div>
            <Formik
                initialValues={{
                    comment: '',
                    name: '',
                    sec: '',
                    count: '',
                    category: ''
                }}
                onSubmit={(values, { resetForm }) => {
                    resetForm();
                }}
                validateOnBlur
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty, setFieldValue }) => (
                    <form className="stage_one_information" onSubmit={(e) => handleRegister(e, handleSubmit, isValid)}>
                        {/* -----------------------------------drop-down------------------------------------------- */}
                        <div>
                            <div className='stage_one_information_inputs'>
                                <div className="stage_one_dropdown-container" role="listbox" tabIndex="0">
                                    <input
                                        className="stage_one_dropdown-input"
                                        name="category"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder='Category*'
                                        value={category}
                                        disabled
                                    />
                                    <div className="stage_one_dropdown-list">
                                        {getCategories.data &&
                                            getCategories.data.map(item => (
                                                <div
                                                    key={item?.id}
                                                    className="stage_one_dropdown-item"
                                                    tabIndex={item?.id}
                                                    role="option"
                                                    aria-selected="true"
                                                    onClick={() => handleItemClick(item, setFieldValue)}
                                                >
                                                    {item.text}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {touched.category && errors.category && <p className="error">{errors.category}</p>}
                                </div>
                                {/* ------------------------------------------------------------------------------------------ */}
                                <div className="stage_one_test_name">
                                    <input
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        placeholder='Test name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        autoComplete='off'
                                    />
                                    {touched.name && errors.name && <p className="error">{errors.name}</p>}
                                </div>
                                <div className='stage_one_sec_count'>
                                    <div className="stage_one_test_sec">
                                        <input
                                            type="number"
                                            name="sec"
                                            value={values.sec}
                                            placeholder='Time in seconds'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            min='1'
                                            autoComplete='off'
                                        />
                                        {touched.sec && errors.sec && <p className="error" min='1'>{errors.sec}</p>}
                                    </div>
                                    <div className="stage_one_test_count">
                                        <input
                                            type='number'
                                            name="count"
                                            value={values.count}
                                            placeholder='Question count'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            min='1'
                                        />
                                        {touched.count && errors.count && <p className="error">{errors.count}</p>}
                                    </div>
                                </div>
                            </div>

                            <div className='stage_one_information_area'>
                                <textarea
                                    name="comment"
                                    value={values.comment}
                                    placeholder='Test Description'
                                    style={{ backgroundImage: `url(${window_background})` }}
                                    maxLength='500'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></textarea>
                                <span>500 sym</span>
                                {touched.comment && errors.comment && <p className="error">{errors.comment}</p>}
                            </div>
                        </div>
                        <button
                            type='submit'
                            style={{
                                backgroundColor: isValid && dirty ? 'var(--light-blue)' : 'white',
                                color: isValid && dirty ? 'white' : 'var(--light-blue)'
                            }}
                        >
                            {t('save')}
                        </button>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default memo(Stage_One_Formik);
