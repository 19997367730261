import React, { memo } from 'react';
import './Loader.css';

function Loader() {
  return (
    <div className='load_div'>
        <span class="loader"></span>
    </div>
  )
}

export default memo(Loader)