import React, { memo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Nav from '../Nav/Nav';

function Home_Wrapper() {
    const location = useLocation();
    const lang = localStorage.getItem('lang') || 'en';

    const hideNav = [
        `/${lang}/thanks-for-the-request`,
        `/${lang}/signed-up`,
        `/${lang}/completed-making`,
    ].includes(location.pathname);

    return (
        <div>
            {!hideNav && <Nav />}
            <Outlet />
            {(location.pathname === `/${lang}/` || location.pathname === `/${lang}/test`) && <Footer />}
        </div>
    );
}

export default memo(Home_Wrapper);
