import React, { useState, useEffect, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectQuestion, selectStg1Inf } from '../../store/storeSlices/createAllSlice/createAllSlice';
import { arrow_right } from '../../icons/icons';
import './PaginatedItems.css';

const PaginatedItems = ({ setInputs, setAnswers, setFileName, setErrors, setTextareaValue }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const stg1Inf = useSelector(selectStg1Inf);
  const question = useSelector(selectQuestion);
  const totalPages = stg1Inf?.question_count || 0;
  const lang = localStorage.getItem('lang') || 'en';
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setCurrentPage(parseInt(id, 10));
    }
  }, [id]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= question.length + 1) {
      setCurrentPage(pageNumber);
      navigate(`/${lang}/stage-4/${pageNumber}`);
      setInputs([]);
      setAnswers([]);
      setFileName('Add Image');
      setErrors({ answerError: '', markError: '', questionError: '' });
      setTextareaValue('');
    }
  };

  const getPaginationPages = () => {
    const pages = [];
    const range = 2;

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 4; i++) {
          pages.push(i);
        }
        pages.push('...');
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1);
        pages.push('...');
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push('...');
        for (let i = currentPage - range; i <= currentPage + range; i++) {
          pages.push(i);
        }
        pages.push('...');
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const paginationPages = getPaginationPages();

  return (
    <div className="pagination">
      {paginationPages.map((page, index) => (
        <React.Fragment key={index}>
          <button
            onClick={() => {
              if (page !== '...') handlePageChange(page);
            }}
            className={`pagination-button ${page === currentPage ? 'active' : ''} ${page.toString() === id ? 'red' : ''}`}
          >
            {page}
          </button>
          {index < paginationPages.length - 1 && <span>{arrow_right}</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default memo(PaginatedItems)
