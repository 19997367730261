import React, { memo } from 'react';
import './Congrate_Contact_Us.css';
import { login_congratulations } from '../../images/images';
import { useNavigate } from 'react-router-dom';

function Congrate_Contact_Us() {
    const lang = localStorage.getItem('lang') || 'en';
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate(`/${lang}/`)
    }
    
    return (
        <div className='congrate_contact_us'>
            <div className='congrate_contact_us_img'>
                <img src={login_congratulations} alt="not found" />
            </div>
            <p>
                Your message was successfully delivered to us.
                We will do our best to answer you as soon as possible!
            </p>
            <button onClick={handleHomeClick}>Back to Main</button>
        </div>
    )
}

export default memo(Congrate_Contact_Us)