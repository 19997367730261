import { createSlice } from "@reduxjs/toolkit";
import { postAnswers } from "./postAnswersApi";

const initialState = {
    data: {},
    loading: 'pending',
};

const postAnswersSlice = createSlice({
    name: 'postAllAnswers',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(postAnswers.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(postAnswers.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(postAnswers.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectPostAnswers = (state) => state.postAllAnswers.data

export const postAnswersReducer = postAnswersSlice.reducer









