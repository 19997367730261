import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import './PrivateRoute.css'
import Loading from '../components/Loading/Loading';
import { getIsAuth } from '../store/storeSlices/AuthSlice/AuthSlice';
import { getCurrentUser } from '../store/storeSlices/AuthSlice/AuthApi';

const PrivateRoute = ({ children }) => {
    const lang = localStorage.getItem('lang');
    const dispatch = useDispatch();
    const isAuth = useSelector(getIsAuth);
    const [loading, setLoading] = useState(true);
    const localIsAuth = localStorage.getItem('isAuth')

    useEffect(() => {
        const fetchData = async () => {
            if (!isAuth && localIsAuth) {
                await dispatch(getCurrentUser());
            }
            setLoading(false);
        };
        fetchData();
    }, [dispatch, isAuth]);

    if (loading) {
        return <Loading />
    }

    return isAuth ? children : <Navigate to={`/${lang}/sign-in`} />;
};


export default memo(PrivateRoute)
