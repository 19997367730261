import React, { memo } from 'react';
import './Footer.css';
import { useSelector } from 'react-redux';
import { getIsAuth } from '../../store/storeSlices/AuthSlice/AuthSlice';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const isAuth = useSelector(getIsAuth);
  const navigate = useNavigate();
  const lang = localStorage.getItem('lang') || 'en';

  const handleLogClick = () => {
    if (!isAuth) {
      navigate(`/${lang}/auth/sign-in`)
    }
  }

  const handleContactClick = () => {
    navigate(`/${lang}/contact-us`)
  }

  const handleTestClick = () => {
    if (isAuth) {
      navigate(`/${lang}/start-making`)
    } else {
      navigate(`/${lang}/auth/sign-in`)
    }
  }

  return (
    <footer>
      <div className="container">
        <div className="footer_content">
          <div className='footer_content_info'>
            <h1>Logo</h1>
            <div>
              <span onClick={handleTestClick}>Make a Test</span>
              <span onClick={handleContactClick}>Contact us</span>
              <span onClick={handleLogClick}>Log in</span>
            </div>
          </div>
          <span>Copyright   2024. All rights reserved.</span>
        </div>
      </div>
    </footer>
  )
}

export default memo(Footer)