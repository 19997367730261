import { createSlice } from "@reduxjs/toolkit";
import { postGoogle, postLogin, postOutGoogle } from "./LoginApi";

const initialState = {
    data: {
        authUser: null,
        access_token: '',
        isAuth: null,
    },
    google:{},
    googleOut:{},
    loading: 'pending',
    error: null,
    authUser:false
};

const postLoginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(postLogin.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(postLogin.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(postLogin.rejected, (state, action) => {
                state.loading = 'rejected'
                state.error = action.payload
                    state.data=action.payload
                
            })

            // -------------------Google------------------

            .addCase(postGoogle.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(postGoogle.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.google = action.payload
            })
            .addCase(postGoogle.rejected, (state, action) => {
                state.loading = 'rejected'
                state.error = action.payload
                if(action.payload){
                    state.data=action.payload
                    state.data.isAuth=false
                }
            })

             // ----------------Google_Out------------------

             .addCase(postOutGoogle.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(postOutGoogle.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.googleOut = action.payload
            })
            .addCase(postOutGoogle.rejected, (state, action) => {
                state.loading = 'rejected'
                state.error = action.payload
                if(action.payload){
                    state.data=action.payload
                    state.data.isAuth=false
                }
            })
    }
})

export const selectLogin = (state) => state.login
export const selectLoginData = (state) => state.login.data
export const selectLoginLoading = (state) => state.login.loading
export const selectLoginError = (state) => state.login.error;
export const selectLoginGoogle = (state) => state.login.google;
export const selectOutGoogle = (state) => state.login.googleOut;

export const loginReducer = postLoginSlice.reducer









