import React, { memo } from 'react';
import './Tests_Logo.css';
import { category_border } from '../../images/images';

function Tests_Logo({ logo }) {
    return (
        <div className='tests_logo' style={{ backgroundImage: `url(${category_border})` }}>
            <h1>{logo}</h1>
        </div>
    )
}

export default memo(Tests_Logo)