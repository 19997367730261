import { createSlice } from "@reduxjs/toolkit";
import { postContactUs } from "./contactUsApi";

const initialState = {
    data: {},
    loading: 'pending',
    error: null
};

const postContactUsSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(postContactUs.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(postContactUs.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(postContactUs.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectContactUs = (state) => state.contactUs.data

export const contactUsReducer = postContactUsSlice.reducer









