import React, { memo, useEffect } from 'react';
import './Menu_Category.css';
import { getCategory } from '../../store/storeSlices/categorySlice/categoryApi';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../../store/storeSlices/categorySlice/categorySlice';
import { mark_left, mark_right } from '../../icons/icons';
import { other_background } from '../../images/images';
import { useNavigate } from 'react-router-dom';
import { getTests } from '../../store/storeSlices/getTestSlice/getTestApi';

function Menu_Category() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const category = useSelector(selectCategory);
    const lang = localStorage.getItem('lang') || 'en';

    useEffect(() => {
        dispatch(getCategory())
    }, [])

    const handleCategoryClick = (item) => {
        dispatch(getTests(item.id));
        navigate(`/${lang}/test/`)
    }

    return (
        <div className="menu_category" style={{ backgroundImage: `url(${other_background})` }}>
            <div className="container">
                <div className="menu_category_content">
                    {
                        category?.data &&
                        category?.data.map(item => (
                            <div key={item?.id} className='menu_category_content_img'>
                                <img src={item?.path} alt="not found" onClick={() => handleCategoryClick(item)} />
                                {item?.text === 'Educational Test' || item?.text === 'Phsychological Tests' ? <span style={{ right: '85%', bottom: '90%' }}>{mark_right}</span> : <span style={{ left: '85%', bottom: '90%' }}>{mark_left}</span>}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(Menu_Category)