import React, { memo } from 'react';
import './Sign_In.css';
import { other_background, sign_in, window_background } from '../../images/images';
import Sign_In_Formik from '../Sign_In_Formik/Sign_In_Formik';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { postGoogle, postLogin } from '../../store/storeSlices/LoginSlice/LoginApi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Sign_In() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lang = localStorage.getItem('lang') || 'en';

    return (
        <div className="sign_in" style={{ backgroundImage: `url(${other_background})` }}>
            <div className="container">
                <div className="sign_in_content">
                    <div className="sign_in_box" style={{ backgroundImage: `url(${window_background})` }}>
                        <div className="sign_in_info">
                            <h1>SIGN IN</h1>
                            <p>By logging in you can see all the tests you made and make changes!</p>
                            <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                    const decodedHeader = jwtDecode(credentialResponse.credential);

                                    const loginWithGoogleObj = {
                                        token: credentialResponse.credential,
                                    };

                                    dispatch(postGoogle(loginWithGoogleObj)).then(res => {
                                        if (res.payload?.access_token) {
                                            navigate(`/${lang}/profile`)
                                        }
                                    })
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                            <Sign_In_Formik />
                            <span className='sign_in_account'>Already have an account? Click to
                                <span onClick={() => navigate(`/${lang}/auth/sign-up`)}> Sign Up!</span>
                            </span>
                        </div>
                        <div className="sign_in_img">
                            <img src={sign_in} alt="not found" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Sign_In)








