import React, { memo } from 'react';
import './Create_Stage_One.css';
import Stage_One_Formik from '../Stage_One_Formik/Stage_One_Formik';
import { create_test, stage_background, window_background } from '../../images/images';
import Arrow from '../Arrow/Arrow';
import Decoration from '../Decoration/Decoration';
import { useTranslation } from 'react-i18next';

function Create_Stage_One() {
  const { t, i18n } = useTranslation();

  return (
    <div className='create_stage_one' style={{ backgroundImage: `url(${stage_background})` }}>
      <div className="container">
        <div className="create_stage_one_content">
          <Decoration />
          <div>
            <div className='create_stage_one_box'>
              <div>
                <div className='create_stage_one_img'>
                  <img src={create_test} alt="not found" />
                </div>
                <textarea style={{ backgroundImage: `url(${window_background})` }} disabled defaultValue={t('stg1')}></textarea>
              </div>
              <Stage_One_Formik />
            </div>
            <Arrow />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Create_Stage_One)