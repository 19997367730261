import { createSlice } from "@reduxjs/toolkit";
import { postTests } from "./postTestApi";

const initialState = {
    data: {},
    loading: 'pending',
};

const postTestsSlice = createSlice({
    name: 'postAllTests',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(postTests.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(postTests.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(postTests.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectPostTests = (state) => state.postAllTests.data

export const postTestsReducer = postTestsSlice.reducer









