import React, { memo } from 'react';
import './Loading.css';

function Loading() {
    return (
        <div className="loading">
            <div className="container">
                <div className="loading_content">
                    <h2>
                        <span>L</span>
                        <span>O</span>
                        <span>A</span>
                        <span>D</span>
                        <span>I</span>
                        <span>N</span>
                        <span>G</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default memo(Loading)