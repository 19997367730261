import React, { memo, useEffect, useRef, useState } from 'react';
import './Educational_Tests.css';
import { educational_decoration_left, educational_decoration_right } from '../../icons/icons';
import { educational_img_1, educational_img_2 } from '../../images/images';
import Tests_Logo from '../Tests_Logo/Tests_Logo';

function Educational_Tests() {
    const educationalRef = useRef(null);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;

            if (educationalRef.current && offset > educationalRef.current.offsetTop - 500) {
                setDisplay(true);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <div className="educational_tests_content" ref={educationalRef}>
            <Tests_Logo logo='Educational Tests' />
            <div className='educational_tests_description'>
                <div>
                    {
                        display &&
                        <div className='educational_tests_img ed-1'>
                            <img src={educational_img_1} alt="not found" />
                        </div>
                    }
                    <span>Save your time</span>
                </div>
                <span style={{ transform: 'translateY(40%)' }}>{educational_decoration_left}</span>
                <div>
                    {
                        display &&
                        <div className='educational_tests_img ed-2'>
                            <img src={educational_img_2} alt="not found" />
                        </div>
                    }
                    <span>Learn More</span>
                </div>
                <span style={{ transform: 'translateY(40%)' }}>{educational_decoration_right}</span>
                <div style={{ padding: '120px 0' }}>
                    <span>Gain More Knowledge</span>
                </div>
            </div>
        </div>
    )
}

export default memo(Educational_Tests)