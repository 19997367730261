import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectCategory } from '../../store/storeSlices/categorySlice/categorySlice';
import { getCategory } from '../../store/storeSlices/categorySlice/categoryApi';
import { selectAllTestsBox, selectAllTestsData, selectAllTestsLoading } from '../../store/storeSlices/getTestSlice/getTestSlice';
import { getAllTestsBox, getTests } from '../../store/storeSlices/getTestSlice/getTestApi';
import Loading from '../Loading/Loading';
import { category_1, category_2, category_3, category_4 } from '../../images/images';
import './Main_Menu_Tests.css';

function Main_Menu_Tests() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState('Category*');
  const getCategories = useSelector(selectCategory);
  const lang = localStorage.getItem('lang') || 'en';
  const allTests = useSelector(selectAllTestsData);
  const loading = useSelector(selectAllTestsLoading);
  const allTestsBox = useSelector(selectAllTestsBox);
  const [unfinished, setUnfinished] = useState({});
  const [color, setColor] = useState('');
  const [width, setWidth] = useState('260px');
  const { search } = useLocation();

  useEffect(() => {
    dispatch(getCategory());
    setCategory('Category*');
    dispatch(getTests(search.split('=')[1] ? search.split('=')[1] : null) )
  }, []);

  useEffect(() => {
    const savedAnswers = localStorage.getItem('userAnswers');
    if (savedAnswers) {
      setUnfinished(JSON.parse(savedAnswers));
    }
  }, []);

  const handleItemClick = (item) => {
    setCategory(item.text);
    dispatch(getTests(item.id));
  };



  const getCategoryImage = (categoryId) => {
    switch (categoryId) {
      case 1:
        return category_3;
      case 2:
        return category_2;
      case 3:
        return category_1;
      case 4:
        return category_4;
      default:
        return null;
    }
  };

  const handleStartClick = (link) => {
    navigate(`/${lang}/test/${link}`)
    if (link !== unfinished?.test_link) {
      localStorage.removeItem("userAnswers");
    }
  }

  useEffect(() => {
    const link = allTests?.data?.find(test => test.link === unfinished?.test_link);

    if (link && unfinished?.answers) {
      const totalQuestions = Number(link.question_count);
      const completedQuestions = unfinished.answers.length;
      const percentage = Math.floor((completedQuestions / totalQuestions) * 100);

      if (percentage <= 33) {
        setColor('#ef5350'); // Red
        setWidth('30%');
      } else if (percentage > 33 && percentage <= 66) {
        setColor('#FFCE58'); // Yellow
        setWidth('60%');
      } else if (percentage > 66) {
        setColor('#3EE200'); // Green
        setWidth('90%');
      }
    }
  }, [unfinished, allTestsBox]);

  return (
    <div className="main_menu_tests">
      <div className="container">
        <div className="main_menu_tests_content">
          <div className="main_menu_tests_content_menu">
            <h1>ONLINE TESTS</h1>
            <span>Educational, Psychological, Entertaining, Else</span>
            <nav className="main_menu_tests_content_dropdown">
              <ul>
                <li>{category}
                  <ul className="main_menu_tests_content_dropdown-menu menu-2">
                    {getCategories.data &&
                      getCategories.data.map((item) => (
                        <li key={item?.id} onClick={() => handleItemClick(item)}>{item?.text}</li>
                      ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <div className="all_tests_box">
            {
              loading === "pending" ? <Loading /> :
                  allTests?.data &&
                  allTests?.data.map((item) => (
                    <div key={item?.id} onClick={() => handleStartClick(item.link)}>
                      <div className="all_tests_box_icon">
                        <img src={getCategoryImage(item?.category_id)} alt="not found" />
                      </div>
                      {
                        unfinished?.answers && unfinished?.test_link === item?.link &&
                        <div className='unfinished_line' style={{ width: '260px' }}>
                          <div className='unfinished-color' style={{ width: width, height: '10px', backgroundColor: color }}></div>
                        </div>
                      }
                      <span>{item?.name}</span>
                    </div>
                  ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Main_Menu_Tests);
