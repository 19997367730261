export const other_tests = require('./Other_Tests.png');
export const educational_tests = require('./Educational_Tests.png');
export const phsychologial_tests = require('./Phsychologial_tests.png');
export const entertaining_tests = require('./Entertaining_Tests.png');
export const category_1 = require('./Category_1.png');
export const category_2 = require('./Category_2.png');
export const category_3 = require('./Category_3.png');
export const category_4 = require('./Category_4.png');
export const category_5 = require('./Category_5.png');
export const test_congratulations = require('./test_congratulations.png');
export const login_congratulations = require('./login_congratulations.png');
export const sign_up = require('./Sign_Up.png');
export const sign_in = require('./sign_in.png');
export const contact_us = require('./Contact_Us.png');
export const delete_test = require('./delete_test.png');
export const write_nickname = require('./write_nickname.png');
export const create_test = require('./create_test.png');
export const test = require('./test.png');
export const header_background = require('./header_background.png');
export const educational_img_1 = require('./educational_img_1.png');
export const educational_img_2 = require('./educational_img_2.png');
export const entertaining_img_1 = require('./entertaining_img_1.png');
export const entertaining_img_2 = require('./entertaining_img_2.png');
export const entertaining_img_3 = require('./entertaining_img_3.png');
export const phsychological_img_1 = require('./phsychological_img_1.png');
export const phsychological_img_2 = require('./phsychological_img_2.png');
export const phsychological_img_3 = require('./phsychological_img_3.png');
export const congrate = require('./congrate.png');
export const window_background = require('./window_background.png');
export const stage_background = require('./stage_background.png');
export const other_background = require('./other_background.png');
export const error_image = require('./error.png');
export const category_border = require('./category_border.png');
export const profile_background = require('./profile_background.png');
export const profile_image = require('./profile_image.png');
export const dance_people = require('./hand-drawn-people-jumping-together_23-2149083967-removebg-preview 1.png');




























