import React, { memo, useRef, useState } from 'react';
import './Congrate_Create_Test.css';
import { test_congratulations } from '../../images/images';
import { copy } from '../../icons/icons';
import { useSelector } from 'react-redux';
import { selectPostTests } from '../../store/storeSlices/postTestSlice/postTestSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Congrate_Create_Test() {
    const navigate = useNavigate();
    const postDataTests = useSelector(selectPostTests);
    const inpRef = useRef(null);
    const [isCopied, setIsCopied] = useState(false);
    const lang = localStorage.getItem('lang') || 'en';
    const { t, i18n } = useTranslation();

    const copyTextToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(inpRef.current.defaultValue);
            console.log('Текст успешно скопирован в буфер обмена!');
        } catch (err) {
            console.error('Ошибка:', err);
        }
        setIsCopied(true)
    };

    const handleHomeClick = () => {
        navigate(`/${lang}/`)
    }

    return (
        <div className='congrate_create_test'>
            <div className='congrate_create_test_img'>
                <img src={test_congratulations} alt="Congratulations" />
            </div>
            <h1>CONGRATULATIONS!</h1>
            <p>{t('congrate_make')}</p>
            <button onClick={handleHomeClick}>Back to Main Page!</button>
            <span>Copy the test link and share with your friends!</span>
            <div className='link_url'>
                <input
                    type="text"
                    autoComplete='off'
                    ref={inpRef}
                    defaultValue={postDataTests.data}
                    disabled
                />
                <span className='copy_icon' onClick={copyTextToClipboard}>{copy}</span>
            </div>
            {isCopied && <div className='link_copy'>Link was copied!</div>}
        </div>
    );
}

export default memo(Congrate_Create_Test);
