import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PrivateRoute.css';
import { getIsAuth } from '../store/storeSlices/AuthSlice/AuthSlice';
import { getCurrentUser } from '../store/storeSlices/AuthSlice/AuthApi';
import Loading from '../components/Loading/Loading';

const PrivateRouteForOutSider = ({ children }) => {
    const dispatch = useDispatch();
    const isAuth = useSelector(getIsAuth);
    const [loading, setLoading] = useState(true);
    const localIsAuth = localStorage.getItem('isAuth');

    useEffect(() => {
        const fetchData = async () => {
            if (!isAuth && localIsAuth) {
                await dispatch(getCurrentUser());

            }
            setLoading(false);
        };
        fetchData();
    }, [dispatch, isAuth]);

    if (loading) {
        return (
            <Loading />
        );
    }

    return children;
};

export default memo(PrivateRouteForOutSider)
