import React, { memo } from 'react';
import './Main.css';
import Main_Menu_Tests from '../Main_Menu_Tests/Main_Menu_Tests';
import Menu_Category from '../Menu_Category/Menu_Category';

function Main() {
  return (
    <main>
      <div className="main_content">
        <Menu_Category />
        <Main_Menu_Tests />
      </div>
    </main>
  )
}

export default memo(Main)