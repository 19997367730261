import React, { memo, useEffect, useState } from 'react';
import './Profile.css';
import { profile_background, profile_image, stage_background } from '../../images/images';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser, setLogout } from '../../store/storeSlices/AuthSlice/AuthSlice';
import { getCurrentUser } from '../../store/storeSlices/AuthSlice/AuthApi';
import { useNavigate } from 'react-router-dom';
import { getUserTests } from '../../store/storeSlices/userTestsSlice/userTestsApi';
import { selectUserTests } from '../../store/storeSlices/userTestsSlice/userTestsSlice';
import { basket, change_delete, done, error } from '../../icons/icons';
import { deleteTest } from '../../store/storeSlices/deleteTestSlice/deleteTestApi';
import { postOutGoogle } from '../../store/storeSlices/LoginSlice/LoginApi';
import { selectLoginGoogle } from '../../store/storeSlices/LoginSlice/LoginSlice';

function Profile() {
  const authUser = useSelector(getAuthUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userTestsBox = useSelector(selectUserTests);
  const lang = localStorage.getItem('lang') || 'en';
  const [deleteHidden, setDeleteHidden] = useState(false);
  const [confirmHidden, setConfirmHidden] = useState(false);
  const [testId, setTestId] = useState(null);
  const loginGoogle = useSelector(selectLoginGoogle);

  console.log(loginGoogle?.access_token,'9999');

  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getUserTests());
  }, [dispatch]);

  useEffect(() => {
    if (confirmHidden) {
      dispatch(getUserTests());
    }
  }, [confirmHidden, dispatch]);

  const handleOutClick = () => {
    dispatch(postOutGoogle({token: localStorage.getItem('token')}));
    localStorage.removeItem("isAuth");
    localStorage.removeItem("token");
    dispatch(setLogout());
    navigate(`/${lang}/`);
  }

  const handleDeleteClick = (id) => {
    setDeleteHidden(true);
    setTestId(id);
  }

  const handleConfirmClick = () => {
    dispatch(deleteTest(testId));
    setDeleteHidden(false);
    setConfirmHidden(true);
  }

  const handleChangeClick = (id) => {
    navigate(`/${lang}/change-test/${id}/1`);
  }

  return (
    <div className="profile" style={{ backgroundImage: `url(${stage_background})` }}>
      <div className="container">
        <div className="profile_content" style={{ opacity: deleteHidden || confirmHidden ? '0.2' : '1' }}>
          <div className="profile_content_user_inform">
            <div className='profile-content_img'>
              <img src={profile_image} alt="not found" />
            </div>
            <span>{authUser?.nickname || authUser?.email}</span>
            <button onClick={handleOutClick}>Log Out</button>
          </div>
          <div className="profile_content_tests_box" style={{ backgroundImage: `url(${profile_background})` }}>
            <h1>Tests you have made</h1>
            <div className='profile_content_tests'>
              {userTestsBox?.data?.map(item => (
                <div key={item?.id} className='profile_content_test'>
                  <div>
                    <span>Test name</span>
                    <p>{item?.name}</p>
                  </div>
                  <span onClick={() => handleChangeClick(item?.id)}>{change_delete}</span>
                  <span onClick={() => handleDeleteClick(item?.id)}>{basket}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* ----------------------------------------hidden_delete------------------------------- */}
        {
          deleteHidden &&
          <div className="test_delete">
            <div className="test_delete_content">
              <span>{error}</span>
              <h1>Are you sure you want to delete this test?</h1>
              <p>By deleting this test you could never recover it!</p>
              <div>
                <button className='cancel-btn' onClick={() => setDeleteHidden(false)}>Cancel</button>
                <button className='delete-btn' onClick={handleConfirmClick}>Delete</button>
              </div>
            </div>
          </div>
        }
        {/* ----------------------------------------confirm_delete------------------------------ */}
        {
          confirmHidden &&
          <div className="confirm_delete">
            <div className="confirm_delete_content">
              <span>{done}</span>
              <h1>Your test was successfully deleted!</h1>
              <button onClick={() => setConfirmHidden(false)}>Got it!</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default memo(Profile)
