import { createSlice } from "@reduxjs/toolkit";
import { deleteTest } from "./deleteTestApi";

const initialState = {
    data: {},
    loading: 'pending',
};

const deleteTestSlice = createSlice({
    name: 'deleteUserTest',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteTest.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(deleteTest.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(deleteTest.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectDeleteTest = (state) => state.deleteUserTest.data

export const deleteTestReducer = deleteTestSlice.reducer









