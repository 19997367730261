import React, { memo, useEffect, useState } from 'react';
import './Decoration.css';
import { decoration_1, decoration_2, decoration_3 } from '../../icons/icons';
import { useLocation, useParams } from 'react-router-dom';

function Decoration() {
    const location = useLocation();
    const lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';
    const { id } = useParams();
    const [display, setDisplay] = useState(false);
    const { firstId, secondId } = useParams();

    useEffect(() => {
        setTimeout(() => {
            setDisplay(true);
        }, 150);
    }, []);

    return (
        <div className="start_making_test">
            <div className="container">
                <div className="start_making_test_content">
                    {display && (
                        <div className="decoration_box">
                            <div
                                className="stage st-1"
                                style={{
                                    background: location.pathname === `/${lang}/start-making`
                                        ? 'var(--light-purple)'
                                        : location.pathname === `/${lang}/stage-1`
                                            ? 'linear-gradient(#DFCBDF, #C394E4)'
                                            : 'white',
                                    color: location.pathname === `/${lang}/start-making`
                                        ? 'white'
                                        : location.pathname === `/${lang}/stage-1`
                                            ? 'white'
                                            : '#C394E4',
                                    border: location.pathname === `/${lang}/stage-1`
                                        ? 'none'
                                        : '3px solid var(--light-purple)',
                                }}
                            >
                                1
                            </div>
                            <span className="decoration decoration_1">
                                <span className="decoration decoration_1">
                                    <svg
                                        width={38}
                                        height={200}
                                        viewBox="0 0 38 194"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <defs>
                                            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                                <stop offset="0%" style={{ stopColor: '#C394E4', stopOpacity: 1 }} />
                                                <stop offset="100%" style={{ stopColor: '#DFCBDF', stopOpacity: 1 }} />
                                            </linearGradient>
                                        </defs>
                                        <path
                                            d="M35.368 2C36.1404 22.8235 39.2427 44.6176 18.922 62.3175C14.4754 66.1905 8.56461 64.6517 10.3074 59.559C11.0907 57.27 13.0022 55.1776 17.2997 55.4398C28.2796 56.1096 29.9579 67.4144 29.9978 72.3949C30.1735 94.2971 9.1272 112.363 3.25907 133.338C-0.216879 145.762 3.44251 154.653 15.0062 164.195C25.0859 172.512 32.3473 181.114 32.3473 192"
                                            stroke={location.pathname === `/${lang}/stage-1` || location.pathname === `/${lang}/stage-2` ? "url(#gradient)" : "#DFCBDF"}
                                            strokeWidth={3}
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </span>
                            </span>
                            <div
                                className="stage st-2"
                                style={{
                                    transform: 'translate(40%, -50%)',
                                    background: location.pathname === `/${lang}/start-making`
                                        ? 'var(--light-purple)'
                                        : location.pathname === `/${lang}/stage-2`
                                            ? 'linear-gradient(#DFCBDF, #C394E4)'
                                            : 'white',
                                    color: location.pathname === `/${lang}/start-making`
                                        ? 'white'
                                        : location.pathname === `/${lang}/stage-2`
                                            ? 'white'
                                            : '#C394E4',
                                    border: location.pathname === `/${lang}/stage-2`
                                        ? 'none'
                                        : '3px solid var(--light-purple)',
                                }}
                            >
                                2
                            </div>
                            <span className="decoration decoration_2">
                                <svg
                                    width={41}
                                    height={187}
                                    viewBox="0 0 41 187"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <defs>
                                        <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                                            <stop offset="0%" style={{ stopColor: '#C394E4', stopOpacity: 1 }} />
                                            <stop offset="100%" style={{ stopColor: '#DFCBDF', stopOpacity: 1 }} />
                                        </linearGradient>
                                    </defs>
                                    <path
                                        d="M36.0853 2C38.6405 21.6066 43.6355 46.0665 28.8664 63.4122C24.9063 68.0631 5.33585 79.7986 2.25023 67.8544C1.67278 65.6191 2.0871 60.5513 3.57278 58.512C7.68404 52.8688 14.9693 59.3125 17.5146 62.0383C30.1216 75.5397 31.5277 92.8419 25.4498 108.567C21.85 117.88 16.8282 126.865 14.7042 136.548C12.924 144.664 14.0242 152.696 15.8614 160.728C17.6209 168.421 22.1986 177.101 22.1986 185"
                                        stroke={location.pathname === `/${lang}/stage-2` || location.pathname === `/${lang}/stage-3` ? "url(#gradient2)" : "#DFCBDF"}
                                        strokeWidth={3}
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </span>
                            <div
                                className="stage st-3"
                                style={{
                                    transform: 'translate(40%, -76%)',
                                    background: location.pathname === `/${lang}/start-making`
                                        ? 'var(--light-purple)'
                                        : location.pathname === `/${lang}/stage-3`
                                            ? 'linear-gradient(#DFCBDF, #C394E4)'
                                            : 'white',
                                    color: location.pathname === `/${lang}/start-making`
                                        ? 'white'
                                        : location.pathname === `/${lang}/stage-3`
                                            ? 'white'
                                            : '#C394E4',
                                    border: location.pathname === `/${lang}/stage-3`
                                        ? 'none'
                                        : '3px solid var(--light-purple)',
                                }}
                            >
                                3
                            </div>
                            <span className="decoration decoration_3">
                                <svg
                                    width={50}
                                    height={205}
                                    viewBox="0 0 50 205"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <defs>
                                        <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
                                            <stop offset="0%" style={{ stopColor: '#C394E4', stopOpacity: 1 }} />
                                            <stop offset="100%" style={{ stopColor: '#DFCBDF', stopOpacity: 1 }} />
                                        </linearGradient>
                                    </defs>
                                    <path
                                        d="M47.4634 2C49.0699 32.3221 50.8203 68.4842 35.9482 92.117C31.018 99.9515 25.3894 100.107 23.6766 87.9536C23.0928 83.811 22.7509 73.2784 23.8447 68.8827C24.1564 67.6302 25.0945 66.1532 25.904 66.5324C28.6157 67.8023 34.1216 73.2946 34.501 75C50.4244 104.56 -19.5766 154 8.00133 203"
                                        stroke={location.pathname === `/${lang}/stage-3` || location.pathname === `/${lang}/stage-4/${id}` ? "url(#gradient3)" : "#DFCBDF"}
                                        strokeWidth={3}
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </span>
                            <div
                                className="stage st-4"
                                style={{
                                    transform: 'translateY(-108%)',
                                    background: location.pathname === `/${lang}/start-making`
                                        ? 'var(--light-purple)'
                                        : location.pathname === `/${lang}/stage-4/${id}` || location.pathname === `/${lang}/change-test/${firstId}/${secondId}`
                                            ? 'linear-gradient(#DFCBDF, #C394E4)'
                                            : 'white',
                                    color: location.pathname === `/${lang}/start-making`
                                        ? 'white'
                                        : location.pathname === `/${lang}/stage-4/${id}` || location.pathname === `/${lang}/change-test/${firstId}/${secondId}`
                                            ? 'white'
                                            : '#C394E4',
                                    border: location.pathname === `/${lang}/stage-4/${id}` || location.pathname === `/${lang}/change-test/${firstId}/${secondId}`
                                        ? 'none'
                                        : '3px solid var(--light-purple)',
                                }}
                            >
                                4
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default memo(Decoration);
