import React, { memo, useEffect, useState } from 'react';
import './Check_One_Test.css';
import Arrow from '../Arrow/Arrow';
import { useNavigate, useParams } from 'react-router-dom';
import { change } from '../../icons/icons';
import { selectQuestion, selectStg1Inf, updateTestText, updateAnswerText } from '../../store/storeSlices/createAllSlice/createAllSlice';
import { useSelector, useDispatch } from 'react-redux';

function Check_One_Test() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [test, setTest] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const tests = useSelector(selectQuestion);
    const stg1Inf = useSelector(selectStg1Inf);
    const dispatch = useDispatch();
    const lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';

    useEffect(() => {
        const selectedTest = tests?.find(t => t.test_id === id);
        setTest(selectedTest);
        setInputValue(selectedTest?.text || '');
    }, [id, tests]);

    const handleClick = () => {
        setIsEditable(!isEditable);
    };

    const handleTestTextChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);

        setTest(prevTest => ({
            ...prevTest,
            text: newValue
        }));

        dispatch(updateTestText({ testId: id, newText: newValue }));
    };

    const handleAnswerTextChange = (index, e) => {
        const newValue = e.target.value;
        const updatedAnswers = [...test.answer_option];
        updatedAnswers[index] = {
            ...updatedAnswers[index],
            text: newValue
        };

        setTest(prevTest => ({
            ...prevTest,
            answer_option: updatedAnswers
        }));

        dispatch(updateAnswerText({ testId: id, answerIndex: index, newText: newValue }));
    };

    return (
        <div className="check_one_test" key={id}>
            <div className="container">
                <div className="check_one_test_content">
                    <div className="check_one_test_page">
                        <span>{id}</span>
                        <span>/</span>
                        <span>{stg1Inf?.question_count}</span>
                    </div>
                    <div className="check_one_test_box">
                        {test.path && (
                            <div className='check_one_test_img'>
                                <img src={test.path} alt="not found" />
                            </div>
                        )}
                        <div className='check_one_test_question'>
                            <span style={{ fontSize: '24px', fontFamily: 'global-font' }}>{test?.test_id}.</span>
                            <textarea
                                value={inputValue}
                                onChange={handleTestTextChange}
                                autoComplete='off'
                                disabled={!isEditable}
                                style={{ textDecoration: !isEditable ? 'none' : 'underline', textUnderlineOffset: '5px' }}
                            ></textarea>
                            <div className="tooltip-container">
                                <span onClick={handleClick}>{change}</span>
                                <span className="tooltip-text">Edit</span>
                            </div>
                        </div>
                        <div className='check_one_test_answers'>
                            {test?.answer_option && test.answer_option.map((answer, index) => (
                                <div key={index}>
                                    <div className='check-round'></div>
                                    <input
                                        type="text"
                                        value={answer.text}
                                        onChange={(e) => handleAnswerTextChange(index, e)}
                                        autoComplete='off'
                                        disabled={!isEditable}
                                        style={{ textDecoration: !isEditable ? 'none' : 'underline', textUnderlineOffset: '5px' }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <Arrow />
                </div>
            </div>
        </div>
    );
}

export default memo(Check_One_Test);
