import React, { memo } from 'react';
import './Home.css';
import Educational_Tests from '../Educational_Tests/Educational_Tests';
import Phsychological_Tests from '../Phsychological_Tests/Phsychological_Tests';
import Entertaining_Tests from '../Entertaining_Tests/Entertaining_Tests';
import Header from '../Header/Header';
import Home_Category from '../Home_Category/Home_Category';

function Home() {
    return (
        <div className='home'>
            <Header />
            <div className="container">
                <Home_Category />
                <Educational_Tests />
                <Phsychological_Tests />
                <Entertaining_Tests />
            </div>
        </div>
    )
}

export default memo(Home)