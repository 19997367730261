import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../axios/axios";

export const postLogin = createAsyncThunk(
    'login/postLogin',
    async (body, thunkAPI) => {
        try {
            const config = {
                method: 'POST',
                url: 'auth/login',
                data: body,
            }
            const response = await instance(config)
            console.log(response.data);
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('isAuth', true);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

// ------------------------Google----------------------------

export const postGoogle = createAsyncThunk(
    'google/postGoogle',
    async (body, thunkAPI) => {
        try {
            const config = {
                method: 'POST',
                url: 'auth/google/call-back',
                data: body,
            }
            const response = await instance(config)
            console.log(response.data);
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('isAuth', true);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

// ------------------------Google_Out-------------------------

export const postOutGoogle = createAsyncThunk(
    'google/postOutGoogle',
    async (body, thunkAPI) => {
        try {
            const config = {
                method: 'POST',
                url: 'auth/logout',
                data: body,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

