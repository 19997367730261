export const convertFileBase64 = (image) => {
    return new Promise((res, rej) => {
        const reader = new FileReader()

        reader.readAsDataURL(image)

        reader.onload = () => {
            const base64String = reader.result
            res(base64String)
        }

        reader.onerror = (err) => {
            rej(err)
        }
    })
}