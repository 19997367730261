import React, { memo, useState } from 'react';
import './Nickname_For_Test.css';
import { stage_background, window_background, write_nickname } from '../../images/images';
import { useNavigate } from 'react-router-dom';
import Decoration from '../Decoration/Decoration';
import { useSelector } from 'react-redux';
import { getAuthUser } from '../../store/storeSlices/AuthSlice/AuthSlice';
import { useTranslation } from 'react-i18next';

function Nickname_For_Test() {
  const navigate = useNavigate();
  const authUser = useSelector(getAuthUser);
  const [nicknameError, setNicknameError] = useState('');
  const lang = localStorage.getItem('lang') || 'en';
  const { t, i18n } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const nickname = e.target[0].value;

    if (nickname === '') {
      setNicknameError('Please fill the field');
    } else if (nickname === authUser?.nickname || nickname === authUser?.email) {
      navigate(`/${lang}/stage-1`);
    } else {
      setNicknameError('Wrong Nickname');
    }
  };

  return (
    <div className="nickname_for_test" style={{ backgroundImage: `url(${stage_background})` }}>
      <div className="container">
        <div className="nickname_for_test_content">
          <Decoration />
          <div>
            <div className='nickname_for_test_info'>
              <div className='nickname_for_test_img'>
                <img src={write_nickname} alt="not found" />
              </div>
              <div className='nickname_for_test_text'>
                <h1>{t('start_nickname.0')}</h1>
                <h1>{t('start_nickname.1')}</h1>
              </div>
            </div>
            <form className='nickname_for_test_information' onSubmit={handleSubmit}>
              <div>
                <input type="text" placeholder='Nickname or Mail' autoComplete='off' />
                <button type="submit">{t('save')}</button>
              </div>
              {nicknameError && <p className='nickname_for_test_error'>{nicknameError}</p>}
              <div className='start_description' style={{ backgroundImage: `url(${window_background})` }}>{t('start_description')}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Nickname_For_Test);
