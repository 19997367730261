import { createSlice } from "@reduxjs/toolkit";
import { getChange, putChange } from "./changeApi";

const initialState = {
    data: {},
    update:{}
};

const getChangeSlice = createSlice({
    name: 'changeQuestion',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getChange.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getChange.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data = action.payload
            })
            .addCase(getChange.rejected, (state, action) => {
                state.loading = 'rejected'
            })

            // ----------------------put-------------------------

            .addCase(putChange.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(putChange.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.update = action.payload
            })
            .addCase(putChange.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    }
})

export const selectGetChangeData = (state) => state.changeQuestion.data
export const selectPutChangeData = (state) => state.changeQuestion.update


export const changeReducer = getChangeSlice.reducer









