import React, { memo, useEffect, useState } from 'react';
import './Header.css';
import { dance_people, design, header_background } from '../../images/images';
import { useNavigate } from 'react-router-dom';

function Header() {
    const [display, setDisplay] = useState(false);
    const lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';
    const navigate = useNavigate();

    const handleMenuClick = () => {
        navigate(`/${lang}/test/`)
    }

    useEffect(() => {
        setTimeout(() => {
            setDisplay(true)
        }, 150)
    }, [])

    return (
        <header style={{ backgroundImage: `url(${header_background})` }}>
            <div className='container'>
                {
                    display &&
                    <div className='header_content'>
                        <div>
                            <div className='header_content_text'>
                                <h1>ONLINE TESTS FOR YOU. GET TO KNOW YOURSELF.</h1>
                                <span>Phsychological, Entertaining, Educational Tests</span>
                            </div>
                            <button onClick={handleMenuClick}>Tests!</button>
                        </div>
                        
                            <img  className='header_content_img' src={dance_people} alt="not found" />
                       
                    </div>
                }
            </div>
        </header>
    )
}

export default memo(Header)