import { configureStore } from "@reduxjs/toolkit";
import { registrReducer } from "./storeSlices/RegisterSlice/RegisterSlice";
import { loginReducer } from "./storeSlices/LoginSlice/LoginSlice";
import { authReduser } from "./storeSlices/AuthSlice/AuthSlice";
import { createAllReducer } from "./storeSlices/createAllSlice/createAllSlice";
import { categoryReducer } from "./storeSlices/categorySlice/categorySlice";
import { answerTypeGradesReducer } from "./storeSlices/getStageSlice/getStageSlice";
import { postTestsReducer } from "./storeSlices/postTestSlice/postTestSlice";
import { getAllTestsReducer } from "./storeSlices/getTestSlice/getTestSlice";
import { contactUsReducer } from "./storeSlices/contactUsSlice/contactUsSlice";
import { userTestsReducer } from "./storeSlices/userTestsSlice/userTestsSlice";
import { deleteTestReducer } from "./storeSlices/deleteTestSlice/deleteTestSlice";
import { postAnswersReducer } from "./storeSlices/postAnswersSlice/postAnswersSlice";
import { changeReducer } from "./storeSlices/changeSlice/changeSlice";


const store = configureStore({
    reducer: {
        registr: registrReducer,
        login: loginReducer,
        auth: authReduser,
        createAll: createAllReducer,
        category: categoryReducer,
        answerTypeGrades: answerTypeGradesReducer,
        postAllTests: postTestsReducer,
        getAllTests: getAllTestsReducer,
        contactUs: contactUsReducer,
        userTests:userTestsReducer,
        deleteUserTest:deleteTestReducer,
        postAllAnswers:postAnswersReducer,
        changeQuestion:changeReducer,
    },
})

export default store