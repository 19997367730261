import React, { memo, useEffect, useRef, useState } from 'react';
import './Create_Stage_Two.css';
import Arrow from '../Arrow/Arrow';
import { stage_background } from '../../images/images';
import Decoration from '../Decoration/Decoration';
import { useDispatch, useSelector } from 'react-redux';
import { selectGradeType, setGradeType } from '../../store/storeSlices/createAllSlice/createAllSlice';
import { selectGrades } from '../../store/storeSlices/getStageSlice/getStageSlice';
import { getGrades } from '../../store/storeSlices/getStageSlice/getStageApi';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Create_Stage_Two() {
    const dispatch = useDispatch();
    const location = useLocation();
    const getGradesType = useSelector(selectGrades);
    const gradeRefs = useRef([]);
    const [activeButton, setActiveButton] = useState(false);
    const lang = localStorage.getItem('lang') || 'en';
    const gradetype = useSelector(selectGradeType);
    const [btnColor, setBtnColor] = useState(false);
    const [numberError, setNumberError] = useState('');
    const { t, i18n } = useTranslation();

    const [formValues, setFormValues] = useState(
        getGradesType?.data?.map(() => ({ lowGrade: '', highGrade: '', description: '' })) || []
    );

    useEffect(() => {
        dispatch(getGrades());
    }, [dispatch]);

    useEffect(() => {
        setFormValues(getGradesType?.data?.map(() => ({ lowGrade: '', highGrade: '', description: '' })) || []);
    }, [getGradesType]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const grade = gradeRefs.current.map((ref, idx) => ({
            test_grade_id: ref.dataset.elid,
            low_grade: formValues[idx].lowGrade,
            high_grade: formValues[idx].highGrade,
            description: formValues[idx].description,
        }));

        const isValid = grade.every(g => g.low_grade && g.high_grade && g.description);

        if (!isValid) {
            setNumberError('Please fill all fields.');
            return;
        }

        if (grade.some(g => Number(g.low_grade) >= Number(g.high_grade))) {
            setNumberError('Low grade must be less than high grade.');
            return;
        }

        for (let i = 0; i < grade.length - 1; i++) {
            if (Number(grade[i].high_grade) >= Number(grade[i + 1].low_grade)) {
                setNumberError('High grade of one index must be less than low grade of the next index.');
                return;
            }
        }

        if (isValid && location.pathname === `/${lang}/stage-2`) {
            setActiveButton(true);
            dispatch(setGradeType(grade));
        } else {
            setActiveButton(false);
        }

        setNumberError('');
    };

    const handleChange = (e, idx, field) => {
        const newFormValues = [...formValues];
        newFormValues[idx][field] = e.target.value;
        setFormValues(newFormValues);

        const allFilled = newFormValues.every(fv => fv.lowGrade.trim() !== '' && fv.highGrade.trim() !== '' && fv.description.trim() !== '');
        if (allFilled) {
            setBtnColor(true)
        }
    };

    useEffect(() => {
        console.log(gradetype, 'gradetype');
    }, [gradetype]);

    return (
        <div className='create_stage_two' style={{ backgroundImage: `url(${stage_background})` }}>
            <div className="container">
                <div className='create_stage_two_content'>
                    <Decoration />
                    <div className="create_stage_two_box">
                        <p>
                        {t('stg2')}
                        </p>
                        <form className='create_stage_two_information' onSubmit={handleSubmit}>
                            {getGradesType?.data &&
                                getGradesType?.data.map((el, idx) => (
                                    <div
                                        ref={(element) => gradeRefs.current[idx] = element}
                                        data-elid={el.id}
                                        key={el.id}
                                        className='characteristic'>
                                        <h1>{el.name}</h1>
                                        <div className='characteristic_number'>
                                            <input
                                                type="number"
                                                placeholder='Low Grade'
                                                autoComplete='off'
                                                min='1'
                                                value={formValues[idx]?.lowGrade || ''}
                                                onChange={(e) => handleChange(e, idx, 'lowGrade')}
                                            />
                                            <div className='until'></div>
                                            <input
                                                type="number"
                                                placeholder='High Grade'
                                                autoComplete='off'
                                                min='1'
                                                value={formValues[idx]?.highGrade || ''}
                                                onChange={(e) => handleChange(e, idx, 'highGrade')}
                                            />
                                        </div>
                                        {numberError && <p className='create_stage_two_error_message'>{numberError}</p>}
                                        <div className='characteristic_area'>
                                            <textarea
                                                placeholder='Description'
                                                maxLength='200'
                                                value={formValues[idx]?.description || ''}
                                                onChange={(e) => handleChange(e, idx, 'description')}
                                            ></textarea>
                                            <span>200 sym</span>
                                        </div>
                                    </div>
                                ))}
                            <button type='submit' style={{
                                backgroundColor: btnColor ? 'var(--light-blue)' : 'white',
                                color: btnColor ? 'white' : 'var(--light-blue)'
                            }}>
                                Save
                            </button>
                        </form>
                        <Arrow activeButton={activeButton} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Create_Stage_Two);
