import { createAsyncThunk } from "@reduxjs/toolkit";
import instance  from "../../../axios/axios";

export const postTests = createAsyncThunk(
    'tests/postTests',
    async (body, thunkAPI) => {
        try {
            const config = {
                method: 'POST',
                url: 'auth/test',
                data: body,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

