import React, { memo } from 'react';
import './Congrate_Sign_Up.css';
import { login_congratulations } from '../../images/images';
import { useNavigate } from 'react-router-dom';

function Congrate_Sign_Up() {
    const navigate = useNavigate();
    const lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';

    const handleMakeTestClick = () => {
        navigate(`/${lang}/auth/sign-in`)
    }

    return (
        <div className='congrate_sign_up'>
            <div className='congrate_sign_up_img'>
                <img src={login_congratulations} alt="not found" />
            </div>
            <h1>CONGRATULATIONS!</h1>
            <p>You have successfully signed up!</p>
            <button onClick={handleMakeTestClick}>Sign In</button>
        </div>
    )
}

export default memo(Congrate_Sign_Up)