import React, { memo, useEffect } from 'react';
import './Arrow.css';
import { left, right } from '../../icons/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreateAll, selectStg1Inf, selectType } from '../../store/storeSlices/createAllSlice/createAllSlice';
import { postTests } from '../../store/storeSlices/postTestSlice/postTestApi';

const Arrow = ({ activeButton }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();
    const stg1Inform = useSelector(selectStg1Inf);
    const answerType = useSelector(selectType);
    const lang = localStorage.getItem('lang') || 'en';
    const testsAll = useSelector(selectCreateAll);

    const validateStg1 = (stg1Inform) => {
        return stg1Inform?.category_id && stg1Inform?.name && stg1Inform?.time_in_seconds && stg1Inform?.question_count;
    };

    const handleLeftClick = () => {
        switch (location.pathname) {
            case `/${lang}/stage-1`:
                navigate(`/${lang}/start-making`);
                break;
            case `/${lang}/stage-2`:
                navigate(`/${lang}/stage-1`);
                break;
            case `/${lang}/stage-3`:
                navigate(`/${lang}/stage-2`);
                break;
            case `/${lang}/stage-4/${id}`:
                navigate(`/${lang}/stage-3`);
                break;
            case `/${lang}/check-test/1`:
                navigate(`/${lang}/stage-4/${stg1Inform.question_count}`);
                break;
            case `/${lang}/check-test/${id}`:
                navigate(`/${lang}/check-test/${Number(id) - 1}`);
                break;
            default:
                break;
        }
    };

    const handleRightClick = () => {
        if (location.pathname === `/${lang}/stage-1` && validateStg1(stg1Inform)) {
            navigate(`/${lang}/stage-2`);
        } else if (location.pathname === `/${lang}/stage-2` && activeButton) {
            navigate(`/${lang}/stage-3`);
        } else if (location.pathname === `/${lang}/stage-3` && answerType) {
            navigate(`/${lang}/stage-4/1`);
        }
        else if (location.pathname === `/${lang}/check-test/${id}`) {
            navigate(`/${lang}/check-test/${Number(id) + 1}`);
        }
    };

    const getBackgroundColor = () => {
        if (location.pathname === `/${lang}/stage-1` && validateStg1(stg1Inform)) {
            return '#9696F0';
        } else if (location.pathname === `/${lang}/stage-2` && activeButton) {
            return '#9696F0';
        } else if (location.pathname === `/${lang}/stage-3` && answerType) {
            return '#9696F0';
        }
        else {
            return 'var(--light-purple)'
        };
    };

    const handlePostTestsClick = () => {

        const questionWithoutId = testsAll?.question.map(({ test_id, ...rest }) => rest);

        const objAllTests = {
            category_id: testsAll?.stg1Inf.category_id,
            name: testsAll?.stg1Inf.name,
            time_in_seconds: testsAll?.stg1Inf.time_in_seconds,
            question_count: testsAll?.stg1Inf.question_count,
            answer_type_id: testsAll?.answer_type_id,
            grade_type: testsAll?.grade_type,
            question: questionWithoutId,
            description:testsAll?.stg1Inf.description
        }

        dispatch(postTests(objAllTests))

        navigate(`/${lang}/completed-making`)
    }

    console.log(testsAll, 'testsAll');

    return (
        <div className='arrow'>
            <span onClick={handleLeftClick}>{left}</span>
            {
                location.pathname === `/${lang}/check-test/${stg1Inform.question_count}` ?
                    <button className='check-btn' onClick={handlePostTestsClick}>Save</button> :
                    <span style={{
                        backgroundColor: location.pathname === `/${lang}/check-test/${id}` ? 'var( --create-test-arrow-after)' : getBackgroundColor(),
                    }}
                        onClick={handleRightClick}>
                        {right}
                    </span>
            }
        </div>
    );
};

export default memo(Arrow);
