import React, { memo } from 'react';
import './Congratulations.css';
import { congrate, other_background, window_background } from '../../images/images';
import Congrate_Contact_Us from '../Congrate_Contact_Us/Congrate_Contact_Us';
import Congrate_Sign_Up from '../Congrate_Sign_Up/Congrate_Sign_Up';
import Congrate_Create_Test from '../Congrate_Create_Test/Congrate_Create_Test';
import { useLocation } from 'react-router-dom';

function Congratulations() {
    const location = useLocation()
    const lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';

    return (
        <div className="congratulations" style={{ backgroundImage: `url(${other_background})` }}>
            <div className="container">
                <div className="congratulations_content">
                    <div className='salute left-salute'>
                        <img src={congrate} alt="not found" />
                    </div>
                    <div className='congratulations_box' style={{ backgroundImage: `url(${window_background})` }}>
                        {location.pathname === `/${lang}/thanks-for-the-request` && <Congrate_Contact_Us />}
                        {location.pathname === `/${lang}/signed-up` && <Congrate_Sign_Up />}
                        {location.pathname === `/${lang}/completed-making` && <Congrate_Create_Test />}
                    </div>
                    <div className='salute right-salute'>
                        <img src={congrate} alt="not found" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Congratulations)