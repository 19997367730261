import { createSlice } from '@reduxjs/toolkit';
import { postLogin } from '../LoginSlice/LoginApi';
import { getCurrentUser } from './AuthApi';

const initialState = {
    authUser: {},
    isAuth: false,
    temp: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            state.authUser = action.payload;
        },
        setIsAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        setLogout: (state) => {
            state.authUser = {};
            state.isAuth = false;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(postLogin.fulfilled, (state, action) => {
                state.authUser = action.payload.authUser;
                state.isAuth = true;
            })

            .addCase(getCurrentUser.fulfilled, (state, action) => {
                state.authUser = action.payload
                state.isAuth = true
            })
            .addCase(getCurrentUser.rejected, (state, action) => {
                state.temp = true
            })
    },
});

export const { setAuth, setIsAuth, setLogout } = authSlice.actions;

export const getAuthUser = (state) => state.auth.authUser;
export const getIsAuth = (state) => state.auth.isAuth;
export const getIsTemp = (state) => state.auth.temp

export const authReduser = authSlice.reducer;
