import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../axios/axios";


// ----------------------------------Get_Category_Tests----------------------

export const getAllTestsBox = createAsyncThunk(
    'allTestsBox/getAllTestsBox',
    async (thunkAPI) => {
        try {
            const config = {
                method: 'GET',
                url: `get-all-tests`,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

// ----------------------------------Get_Category_Tests----------------------

export const getTests = createAsyncThunk(
    'tests/getTests',
    async (categoryId, thunkAPI) => {

        const requestId = categoryId !== null ? `?category_id=${categoryId}` : ''

        try {
            console.log(categoryId,99);
            const config = {
                method: 'GET',
                url: `get-all-tests${requestId}`,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

// --------------------------------Get_One_Test----------------------------

export const getTest = createAsyncThunk(
    'test/getTest',
    async (link, thunkAPI) => {
        try {
            const config = {
                method: 'GET',
                url: `test/${link}`,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

