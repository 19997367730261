import { Formik } from 'formik';
import React, { memo, useEffect } from 'react';
import * as yup from 'yup';
import './Sign_Up_Formik.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectRegistrData, selectRegistrError } from '../../store/storeSlices/RegisterSlice/RegisterSlice';
import { postRegistr } from '../../store/storeSlices/RegisterSlice/RegisterApi';

function Sign_Up_Formik() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registrData = useSelector(selectRegistrData)
    const errorMessage = useSelector(selectRegistrError);
    const lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';
    const validationSchema = yup.object().shape({
        name: yup.string().required('Пожалуйста, заполните это поле'),
        password: yup.string()
            .min(6, 'Минимум 6 символов*')
            .required('Пожалуйста, заполните это поле'),
    })

    const handleRegister = async (e, handleSubmit, isValid) => {
        e.preventDefault();
        handleSubmit()

        const registration = {
            nickname_or_email: e.target[0].value,
            password: e.target[1].value,
        }

        if (isValid && e.target[0].value && e.target[1].value) {
            dispatch(postRegistr(registration))
        }
    }

    useEffect(() => {
        registrData.success && navigate(`/${lang}/signed-up`)
    }, [registrData.success])

    return (
        <div>
            <Formik
                initialValues={{
                    name: '',
                    password: ''
                }}

                onSubmit={(values, { resetForm }) => {
                    resetForm()
                }}

                validateOnBlur

                validationSchema={validationSchema}>

                {
                    ({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                        <form className="sign_up_information" onSubmit={(e) => handleRegister(e, handleSubmit, isValid)}>
                            <div className="sign_up_name_inp">
                                <input type="text" name="name" placeholder='Nickname or Mail' value={values.name} onChange={handleChange} onBlur={handleBlur} autoComplete='off' />
                                {touched.name && errors.name && <p className="error">{errors.name}</p>}
                            </div>
                            <div className="sign_up_password_inp">
                                <input type="password" name="password" placeholder='Password' value={values.password} onChange={handleChange} onBlur={handleBlur} autoComplete='off' />
                                {touched.password && errors.password && <p className="error">{errors.password}</p>}
                            </div>
                            {errorMessage &&
                                <p style={{ color: 'red', fontFamily: 'global-font', fontSize: '14px' }}>{errorMessage}</p>}
                            <button type='submit' className='sign_up_btn'>Sign Up</button>
                        </form>
                    )
                }
            </Formik>
        </div>
    )
}

export default memo(Sign_Up_Formik)