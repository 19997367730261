import React, { memo, useEffect, useRef, useState } from 'react';
import './Home_Category.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../../store/storeSlices/categorySlice/categorySlice';
import { getCategory } from '../../store/storeSlices/categorySlice/categoryApi';
import { useNavigate } from 'react-router-dom';
import { getTests } from '../../store/storeSlices/getTestSlice/getTestApi';

function Home_Category() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const category = useSelector(selectCategory);
  const homeCategoryRef = useRef(null);
  const [display, setDisplay] = useState(false);
  const lang = localStorage.getItem('lang') || 'en';

  useEffect(() => {
    dispatch(getCategory())
  }, [])

  const handleItemClick = (item) => {
    navigate(`/${lang}/test/?category=${item.id}`)
    // dispatch(getTests(item?.id))
  };


  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      if (homeCategoryRef.current && offset > homeCategoryRef.current.offsetTop - 500) {
        setDisplay(true);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);


  return (
    <div className="home_category" ref={homeCategoryRef}>
      <h1>Test Categories</h1>
      <div className='home_category_box'>
        {
          display &&
          category?.data &&
          category?.data.map(item => (
            <div key={item?.id} className='home_category_item' >
              <div className='home_category_img'>
                <img src={item?.path} alt="not found" />
              </div>
              <button onClick={() => handleItemClick(item)}>Go to Tests!</button>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default memo(Home_Category)