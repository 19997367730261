import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    ru: {
      translation: {
        start_nickname: ['Создайте ваш индивидуальный никнейм,', 'для создания теста внутри системы.'],
        save: 'Сохранить',
        start_description: 'Дорогие друзья прошу вас внимательно ознакомится с правилами заполнения тестов внутри системы. Для создание теста необходимо заполнить пошагово следующие  параметры.',
        stg1: `Выберите из предложенного списка, соответствующую категорию теста.
        Укажите название теста.Время за которое должны пройти тест, тестируемые пользователи.
        Колличество вопрос в тесте, примечание максимальное количество вопросов 20.
        Опишите содержимое теста, какая цель и задачи закладываются в данном тесте.`,
        stg2: `Далее обязательно указать промежуток уровней пройденного теста и соответствующее ему 
        описание. Это для итогового подсчета баллов, чтобы дать обозначить результаты и их значения. `,
        stg3: `Выберите один из вариантов правильного ответа, в нашей системе можно применить метод 
        правильного ответа, если выбирайте вариант с одним правильным ответом то во всех вариантах нужно обязательно отметить правильный ответ. 
        В случае выбора нескольких правильных ответов вы можете ставить варианты правильных ответов в множественном числе.`,
        stg4: `Описание Вопроса и Прикрепление фотографии. 
        В заполнении теста, отображается то количество вопросов которое было создано в шаге 1. 
        Обязательно укажите количество баллов за ответ от 0 и выше /в соответствии с указанным промежутком/ 
        Минимальное количество вопрос 3.  
        Обязательно указывать варианты правильных ответов.`,
        congrate_make:`В конце можно посмотреть и перепроверить созданный тест.
        После вы можете поделится своим тестом.`,
      },
    },
    en: {
      translation: {
        start_nickname: ['WRITE DOWN YOUR NICKNAME', 'TO CONTINUE MAKING TEST.'],
        save: 'Save',
        start_description: '',
        stg1: '',
        stg2: '',
        stg3: '',
        stg4:'',
        congrate_make:'',
      },
    },
  },
  lng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
  fallbackLng: localStorage.getItem('lang'),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
