import React, { memo } from 'react';
import Nav_Menu from '../Nav_Menu/Nav_Menu';
import './Nav.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsAuth } from '../../store/storeSlices/AuthSlice/AuthSlice';
import { mobile_menu, user } from '../../icons/icons';
import Language from '../Language/Language';

function Nav() {
    const lang = localStorage.getItem('lang') || 'en';
    const isAuth = useSelector(getIsAuth);
    const navigate = useNavigate();

    const handleLogClick = () => {
        navigate(`/${lang}/auth/sign-in`)
    }

    const handleProfileClick = () => {
        navigate(`/${lang}/profile`)
    }

    const handleHomeClick = () => {
        navigate(`/${lang}/`)
    }

    const handleMakeClick = () => {
        if (isAuth) {
            navigate(`/${lang}/start-making`)
        } else {
            navigate(`/${lang}/auth/sign-in`)
        }
    }

    return (
        <div className='nav'>
            <div className="container">
                <div className='window_menu'>
                    <div className="nav_content">
                        <span onClick={handleHomeClick}>logo</span>
                        <div>
                            <Nav_Menu />
                            <Language />
                            {isAuth ? <span className='user-icon' onClick={handleProfileClick}>{user}</span> : <button onClick={handleLogClick}>Log In</button>}
                        </div>
                    </div>
                </div>
                <div className='mobile_menu'>
                    <div className='resp_nav_content'>
                        <div>
                            <nav className="resp_nav_content_dropdown">
                                <ul>
                                    <li>{mobile_menu}
                                        <ul className="resp_nav_content_dropdown-menu menu-3">
                                            <li>
                                                {isAuth ? <span className='user-icon' onClick={handleProfileClick}>{user}</span> : <span onClick={handleLogClick}>Log In</span>}
                                            </li>
                                            <li onClick={() => navigate(`/${lang}/test`)}>Tests</li>
                                            <li onClick={handleMakeClick}>Make Test</li>
                                            <li onClick={() => navigate(`/${lang}/contact-us`)}>Contact Us</li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                            <Language />
                        </div>
                        <span onClick={handleHomeClick}>logo</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Nav)