import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../axios/axios";

// -------------------------------Test-Get--------------------------------


export const getChange = createAsyncThunk(
    'change/getChange',
    async (id) => {
        try {
            const config = {
                method: 'GET',
                url: `auth/edit/${id}`,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch {
            console.log(Error, 'Error');
        }
    }
)

// -------------------------------Test-Put--------------------------------

export const putChange = createAsyncThunk(
    'change/putChange',
    async ({data, id}) => {
        // console.log(body,'ooo');
        try {
            const config = {
                method: 'PUT',
                url: `auth/update/${id}`,
                data: data,
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch {
            console.log(Error, 'Error');
        }
    }
)