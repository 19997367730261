import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

instance.interceptors.request.use(
  (config) => {
    const lang = localStorage.getItem('lang') || "en";
    const token = localStorage.getItem('token') || null;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Accept-Language"] = lang;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
