import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PrivateRoute.css'
import { Navigate } from 'react-router-dom';
import Loading from '../components/Loading/Loading';
import { getIsAuth } from '../store/storeSlices/AuthSlice/AuthSlice';
import { getCurrentUser } from '../store/storeSlices/AuthSlice/AuthApi';

const PrivateRouteForRegAndLog = ({ children }) => {
    const dispatch = useDispatch();
    const isAuth = useSelector(getIsAuth);
    const [loading, setLoading] = useState(true);
    const localIsAuth = localStorage.getItem('isAuth')
    const token = localStorage.getItem('token')

    useEffect(() => {
        const fetchData = async () => {
            if (localIsAuth && token) {
                await dispatch(getCurrentUser());
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    if (loading) {
        return <Loading />
    }

    return !isAuth ? children : <Navigate to={/${lang}/} />;
};


export default memo(PrivateRouteForRegAndLog)
