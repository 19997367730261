import React, { memo } from 'react';
import './Errore_Page.css';
import { error_image, other_background } from '../../images/images';
import { useNavigate } from 'react-router-dom';

function Errore_Page() {
  const navigate = useNavigate();
  const lang = localStorage.getItem('lang') || 'en';

  const handleHomeClick = () => {
    navigate(`/${lang}/`)
  }
  return (
    <div className='error_page' style={{ backgroundImage: `url(${other_background})` }}>
      <div className="container">
        <div className="error_page_content">
          <div className="error_page_box">
            <div className='error_page_info'>
              <h1>WHOOPS, THAT PAGE IS GONE.</h1>
              <p>
                The link you clicked may be broken or the page may have been removed.
                You can try again or go back to the main page.
              </p>
              <button onClick={handleHomeClick}>Main page</button>
            </div>
            <div className='error_page_img'>
              <img src={error_image} alt="not found" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Errore_Page)