import React, { memo, useEffect, useState } from 'react';
import './Create_Stage_Three.css';
import { stage_background, test, window_background } from '../../images/images';
import Arrow from '../Arrow/Arrow';
import Decoration from '../Decoration/Decoration';
import { useDispatch, useSelector } from 'react-redux';
import { selectType, setType } from '../../store/storeSlices/createAllSlice/createAllSlice';
import { selectAnswerType } from '../../store/storeSlices/getStageSlice/getStageSlice';
import { getAnswerType } from '../../store/storeSlices/getStageSlice/getStageApi';
import { useTranslation } from 'react-i18next';

function Create_Stage_Three() {
    const dispatch = useDispatch();
    const getType = useSelector(selectAnswerType);
    const answerType = useSelector(selectType);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        dispatch(getAnswerType());
    }, [dispatch]);

    const handleRadioChange = (e, id) => {
        const newAnswerTypeId = parseInt(id);
        dispatch(setType(newAnswerTypeId));
    };

    useEffect(() => {
        console.log(answerType, 'answerType');
    }, [answerType]);

    return (
        <div className='create_stage_three' style={{ backgroundImage: `url(${stage_background})` }}>
            <div className="container">
                <div className="create_stage_three_content">
                    <Decoration />
                    <div>
                        <div className='create_stage_three_box'>
                            <div className='create_stage_three_img'>
                                <img src={test} alt="not found" />
                            </div>
                            <div className='create_stage_three_info' style={{ backgroundImage: `url(${window_background})` }}>
                                <p>
                                    {t('stg3')}
                                </p>
                                <span>Count of right answers</span>
                                <div className='create_stage_three_information'>
                                    {
                                        getType?.data &&
                                        getType?.data.map(el => (
                                            <div key={el.id}>
                                                <input type='radio' id={`rdo${el.id}`} value={el.id} className="radio-input" name="radio-group" onChange={(e) => handleRadioChange(e, el.id)} />
                                                <label htmlFor={`rdo${el.id}`} className="radio-label">
                                                    <span className="radio-border"></span>
                                                    {el.name}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <Arrow />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Create_Stage_Three);
