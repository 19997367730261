import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../translateFolder/i18n';
import './Language.css';

function Language() {
  const lang = localStorage.getItem('lang');
  const [deflang, setDefLang] = useState('');
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const prevLng = localStorage.getItem('lang');
    const pathname = window.location.pathname;
    const resault = pathname.replace('/' + prevLng, '/' + lng);
    localStorage.setItem('lang', lng);
    window.location.href = resault
  };

  return (
    <div className='language'>
      <div className='language_menu'>
        <nav className="language_content_dropdown">
          <ul>
            <li>LANG
              <ul className="language_content_dropdown-menu menu-4">
                <li>
                  <p id='ru' onClick={() => changeLanguage('ru')}>Ru</p>
                </li>
                <li>
                  <p id='en' onClick={() => changeLanguage('en')}>En</p>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Language;
