import { Formik } from 'formik';
import React, { memo } from 'react';
import * as yup from 'yup';
import './Sign_In_Formik.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postLogin } from '../../store/storeSlices/LoginSlice/LoginApi';
import { selectLoginError } from '../../store/storeSlices/LoginSlice/LoginSlice';

function Sign_Up_Formik() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errorMessage = useSelector(selectLoginError);
    const lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';

    const validationSchema = yup.object().shape({
        name: yup.string().required('Пожалуйста, заполните это поле'),
        password: yup.string()
            .min(6, 'Минимум 6 символов*')
            .required('Пожалуйста, заполните это поле'),
    })

    const handleRegister = async (e, handleSubmit, isValid) => {
        e.preventDefault();
        handleSubmit()

        const body = {
            nickname_or_email: e.target[0].value,
            password: e.target[1].value
        }

        if (isValid && e.target[0].value && e.target[1].value) {
            dispatch(postLogin(body)).then(res => {
                if (res.payload?.access_token) {
                    navigate(`/${lang}/profile`)
                }
            })
        }
    }

    return (
        <div>
            <Formik
                initialValues={{
                    name: '',
                    password: ''
                }}

                onSubmit={(values, { resetForm }) => {

                    resetForm()
                }}

                validateOnBlur

                validationSchema={validationSchema}>

                {
                    ({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                        <form className="sign_in_information" onSubmit={(e) => handleRegister(e, handleSubmit, isValid)}>
                            <div className="sign_in_name_inp">
                                <input type="text" name="name" placeholder='Nickname or Mail' value={values.name} onChange={handleChange} onBlur={handleBlur} autoComplete='off' />
                                {touched.name && errors.name && <p className="error">{errors.name}</p>}
                            </div>
                            <div className="sign_in_password_inp">
                                <input type="password" name="password" placeholder='Password' value={values.password} onChange={handleChange} onBlur={handleBlur} autoComplete='off' />
                                {touched.password && errors.password && <p className="error">{errors.password}</p>}
                            </div>
                            {
                                errorMessage?.error &&
                                <p style={{ color: 'red', fontFamily: 'global-font', fontSize: '14px' }}>{errorMessage?.error}</p>
                            }
                            <button type='submit' className='sign_in_btn'>Sign In</button>
                        </form>
                    )
                }
            </Formik>
        </div>
    )
}

export default memo(Sign_Up_Formik)