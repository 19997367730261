import { createAsyncThunk } from "@reduxjs/toolkit";
import  instance  from "../../../axios/axios";

export const getCurrentUser = createAsyncThunk(
    'auth/getCurrentUser',
    async (_, thunkAPI) => {
        try {
            const config = {
                method: "post",
                url: 'auth/me',
            };

            const response = await instance(config);

            return response?.data;
        } catch (error) {

            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
