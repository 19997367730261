import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../axios/axios";

export const getCategory = createAsyncThunk(
    'category/getCategory',
    async () => {
        try {
            const config = {
                method: 'GET',
                url: 'get-categories',
            }
            const response = await instance(config)
            console.log(response.data);
            return response.data
        } catch {
            console.log(Error, 'Error');
        }
    }
)


