import React, { memo } from 'react';
import './Nav_Menu.css';
import Nav_Menu_Item from '../Nav_Menu_Item/Nav_Menu_Item';
import { getIsAuth } from '../../store/storeSlices/AuthSlice/AuthSlice';
import { useSelector } from 'react-redux';

function Nav_Menu() {
    const isAuth = useSelector(getIsAuth);

    return (
        <ul className='nav_menu'>
            <Nav_Menu_Item txt='Tests' url='test' />
            <Nav_Menu_Item txt='Make Test' url={isAuth ? 'start-making' : 'auth/sign-in'} />
            <Nav_Menu_Item txt='Contact Us' url='contact-us' />
        </ul>
    )
}

export default memo(Nav_Menu)